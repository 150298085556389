import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import { getIsExpanded, analysisActions } from "~/action-panel";
import { updateFieldInfo } from "~/action-panel/components/field-module/actions";
import {
    getFieldInfoModel,
    getFieldBoundaryOverlapAction,
    getFieldBoundaryTransferGeometries,
    getIsFieldBoundaryTransferOpen,
} from "~/action-panel/components/field-module/selectors";
import { importWizardSelectors } from "~/action-panel/components/import-module";
import { getTheUserGuid } from "~/login";
import { getUser } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import {
    actions as recsEventsActions,
    analysisSelectors,
    eventsSelectors,
    recsSelectors,
    selectors as recsEventsSelectors,
} from "~/recs-events";
import { actions as fieldActions } from "~/action-panel/components/field-module";
import { Toolset } from "@ai360/core";

import * as actions from "../actions";
import { MapTools } from "../components/map-tools.4x";
import * as selectors from "../selectors";
import * as mapActions from "../../map-control/actions";
import * as mapSelectors from "../../map-control/selectors";
import * as nonFieldFeatureInformationActions from "~/action-panel/components/layer-module/components/non-field-feature-information/actions";

const mapStateToProps = (state) => {
    const activeToolset = selectors.getActiveToolset(state);
    const toolsetPayload = selectors.getToolsetPayload(state);
    const eventGuidToEventSummaryMap = eventsSelectors.getEventGeneralGuidToEventSummaryMap(state);
    const fieldGuid =
        activeToolset !== Toolset.ZONE_EDIT &&
        activeToolset !== Toolset.ZONE_COPY &&
        activeToolset !== Toolset.SAMPLING &&
        activeToolset !== Toolset.SCOUTING_ZONE_EDIT &&
        activeToolset !== Toolset.ANALYSIS
            ? null
            : toolsetPayload && toolsetPayload.field && toolsetPayload.field.fieldGuid;
    const areaIdToHasDataMap =
        eventsSelectors.getEventAreaIdToHasDataMap(state, fieldGuid) ||
        recsSelectors.getRecAreaIdToHasDataMap(state, fieldGuid) ||
        analysisSelectors.getManagementAreaIdToHasDataMap(state, fieldGuid) ||
        new Map();
    const { copyZonesFromToMap, fieldGuidToCurrentAreaId } =
        recsEventsSelectors.getZonesState(state);
    const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    const {
        selectedSamplePointGuidIdSet,
        gridOrientationSettings,
        gridStandardUnitSettings,
        soilSampleResults,
        tissueSampleResults,
        fieldGuidToEventDetails,
    } = eventsSelectors.getModuleState(state);
    const { fieldGuidToRecDetails } = recsSelectors.getModuleState(state);
    const recEventDetails = fieldGuidToEventDetails.has(fieldGuid)
        ? fieldGuidToEventDetails.get(fieldGuid)
        : fieldGuidToRecDetails.get(fieldGuid);
    return {
        actionPanelIsExpanded: getIsExpanded(state),
        activeMapTool: selectors.getActiveMapTool(state),
        activeToolset,
        areaIdToHasDataMap,
        areaUnits: selectors.getAreaUnits(state),
        batchReset: selectors.getBatchReset(state),
        bufferOptions: selectors.getBufferOptions(state),
        bufferReady: selectors.getBufferReady(state),
        copyZonesFromToMap,
        currentAreaId,
        currentFieldEditGeometries: selectors.getFieldEditGeometries(state),
        currentProcessCounts: selectors.getCurrentProcessCounts(state),
        eventGuidToEventSummaryMap,
        fieldModel: getFieldInfoModel(state),
        fieldBoundaryOverlapAction: getFieldBoundaryOverlapAction(state),
        fieldBoundaryTransferModalOpen: getIsFieldBoundaryTransferOpen(state),
        fieldBoundaryTransferGeometries: getFieldBoundaryTransferGeometries(state),
        gridOrientationSettings: gridOrientationSettings,
        gridRemoveFlag: selectors.getGridRemoveFlag(state),
        importFieldList: importWizardSelectors.getImportFieldList(state),
        isEquationRec: recsSelectors.getIsEquationRec(state),
        isEquationRecProcessing: recsSelectors.getIsEquationRecProcessing(state),
        isFromEquationRec: eventsSelectors.getIsFromEquationRec(state),
        isImportedEventRec:
            eventsSelectors.getIsImported(state) || recsSelectors.getIsImported(state),
        isNewEventRec: recsEventsSelectors.getIsNewEventRec(state),
        isProcessing: selectors.getToolsProcessing(state),
        lengthUnits: selectors.getLengthUnits(state),
        recEventDetails,
        samplingEvent: eventsSelectors.getSampleAgEvent(state, fieldGuid),
        samplingGridSettings: gridStandardUnitSettings,
        samplingViewOnly: soilSampleResults != null || tissueSampleResults != null,
        selectedSites: selectedSamplePointGuidIdSet,
        toolsetDisabled: selectors.getToolsetDisabled(state),
        toolsetPayload,
        traceApplyFlag: selectors.getTraceApplyFlag(state),
        traceResetFlag: selectors.getTraceResetFlag(state),
        userGuid: getTheUserGuid(state),
        userInfo: getUser(state),
        visibleToolbarMenuDimensions: selectors.getVisibleToolbarMenuDimensions(state),
        zoom: mapSelectors.getZoomLevel(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    onAddZone: (fieldGuid, eventTypeName, eventAreaId, currentAreaId, calculatedArea, shape) =>
        dispatch(
            recsEventsActions.addArea(
                fieldGuid,
                eventTypeName,
                eventAreaId,
                currentAreaId,
                calculatedArea,
                shape
            )
        ),
    onClearRemoveGridFlag: () => dispatch(actions.setRemoveGridFlag(false)),
    onClearTraceFlags: () => dispatch(actions.clearTraceFlags()),
    onFieldBoundaryOverlapDetected: () => dispatch(fieldActions.fieldBoundaryOverlapDetected()),
    onPushToasterMessage: (message, type, values, autoClose = true) =>
        dispatch(notificationActions.pushToasterMessage(message, type, values, autoClose)),
    onPushToasterMessageDirect: (message, type, autoClose) =>
        dispatch(notificationActions.pushToasterMessage(message, type, null, autoClose)),
    onResetClassifiedZones: (fieldGuid, areaIdToPolygonMap, areaIdToClassBreaksMap) =>
        dispatch(
            recsEventsActions.resetClassifiedAreaPolygons(
                fieldGuid,
                areaIdToPolygonMap,
                areaIdToClassBreaksMap
            )
        ),
    onResetZones: (fieldGuid, areaIdToPolygonMap) =>
        dispatch(recsEventsActions.resetAreaPolygons(fieldGuid, areaIdToPolygonMap)),
    onRestoreAreaList: (fieldGuid, restoreId) =>
        dispatch(recsEventsActions.restoreAreaList(fieldGuid, restoreId)),
    onSaveAreaList: (fieldGuid, restoreId) =>
        dispatch(recsEventsActions.saveAreaList(fieldGuid, restoreId)),
    onSetActiveMapTool: (tool) => dispatch(actions.setActiveMapTool(tool)),
    onSetActiveToolsetOnly: (toolset) => dispatch(actions.setActiveToolsetOnly(toolset)),
    onSetActiveZone: (fieldGuid, areaId) =>
        dispatch(recsEventsActions.setCurrentAreaId(fieldGuid, areaId)),
    onSetAreaIsIncluded: (fieldGuid, areaId, isIncluded) =>
        dispatch(recsEventsActions.setAreaIsIncluded(fieldGuid, areaId, isIncluded)),
    onSetBatchReset: (batchReset) => dispatch(actions.setBatchReset(batchReset)),
    onSetBufferOptions: (options) => dispatch(actions.setBufferOptions(options)),
    onSetBufferReady: (ready) => dispatch(actions.setBufferReady(ready)),
    onSetCanClip: (canClip) => dispatch(actions.setCanClip(canClip)),
    onSetCanClipPending: (pending) => dispatch(actions.setCanClipPending(pending)),
    onSetCopyToAreaIdSet: (areaIdSet) => dispatch(recsEventsActions.setCopyToAreaIdSet(areaIdSet)),
    onSetFieldBoundaryImportFileGuid: (importFileGuid) =>
        dispatch(fieldActions.setFieldBoundaryImportFileGuid(importFileGuid)),
    onSetFieldEditGeometries: (geometry) => dispatch(actions.setFieldEditGeometries(geometry)),
    onSetFieldBoundariesToClip: (fieldBoundaries) =>
        dispatch(fieldActions.setFieldBoundariesToClip(fieldBoundaries)),
    onSetFieldBoundaryTransferGeometries: (geometries) =>
        dispatch(fieldActions.setFieldBoundaryTransferGeometries(geometries)),
    onSetFieldBoundaryTransferModalOpen: (isOpen) =>
        dispatch(fieldActions.setFieldBoundaryTransferModalOpen(isOpen)),
    onSetMinMaxGridCell: (minAreaAcres, maxAreaAcres) =>
        dispatch(recsEventsActions.setGridMinMaxArea(minAreaAcres, maxAreaAcres)),
    onSetMapIsLoading: (isLoading) => dispatch(mapActions.setIsLoading(isLoading)),
    onSetNonFieldFeatureInformationFeatureGeometries: (geometries) =>
        dispatch(nonFieldFeatureInformationActions.setFeatureGeometries(geometries)),
    onSetGridOrientationSettings: (settings) =>
        dispatch(recsEventsActions.setGridOrientationSettings(settings)),
    onSetSamplePoints: (samplePoints) =>
        dispatch(recsEventsActions.setEventSamplePoints(samplePoints)),
    onSetSelectedSamplePoints: (guidSet) =>
        dispatch(recsEventsActions.setSelectedSamplePoints(guidSet)),
    onSetToolsetDisabled: (disabled) => dispatch(actions.setToolsetDisabled(disabled)),
    onSetToolsProcessing: (isProcessing) => dispatch(actions.setToolsProcessing(isProcessing)),
    onSetTraceCounts: (traceCompleteCount, totalCount, traceSites) =>
        dispatch(recsEventsActions.setTraceCounts(traceCompleteCount, totalCount, traceSites)),
    onSetTraceSites: (traceSites) => dispatch(recsEventsActions.setTraceSites(traceSites)),
    onSetVisibleToolbarMenuDimensions: (dimensions) =>
        dispatch(actions.setVisibleToolbarMenuDimensions(dimensions)),
    onSetZoneFileGuid: (fieldGuid, importFileGuid) =>
        dispatch(recsEventsActions.setZoneFileGuid(fieldGuid, importFileGuid)),
    onSetZonesLoading: (loading) => dispatch(recsEventsActions.setZonesLoading(loading)),
    onUpdateAnalysisZones: (zones, fieldBoundaryGuid, zonesMap) =>
        dispatch(analysisActions.updateZones(zones, fieldBoundaryGuid, zonesMap)),
    onUpdateZones: (fieldGuid, zonesMap) =>
        dispatch(recsEventsActions.updateAreaPolygons(fieldGuid, zonesMap)),
    setZoomToNonFieldFeatures: (features) =>
        dispatch(mapActions.setZoomToNonFieldFeatures(features)),
    setZoomToField: (fieldGuid) => dispatch(mapActions.setZoomToField(fieldGuid)),
    updateFieldModel: (f) => dispatch(updateFieldInfo(f)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MapTools));
