import React, { Component } from "react";
import PropTypes from "prop-types";
import CustomPropTypes from "~/utils/proptypes";
import { injectIntl, intlShape } from "react-intl";
import { keywords } from "~/admin/containers/keywords";

import { model, service } from "./../data";
import {
    ABBREVIATION,
    ADD,
    adminData,
    FALSE,
    GUID,
    ID,
    NAME,
    PICKLIST_GUID,
    TRUE,
    VALUE,
} from "~/admin/data";
import {
    getAgBytesErrorClassNames,
    mapToPicklistValue,
    onTextChange,
    onTextChangeMultiProp,
    prepareSelectableOptions,
} from "~/admin/utils";
import { PersonalityTypes } from "~/utils/keywords";
import { PICKLIST_PHONE_TYPE, PICKLIST_UNIT_SYSTEM } from "~/core/picklist/picklist-names";
import { messages } from "../../i18n-messages";
import { messages as agBytesMessages } from "~/admin/agBytes/i18n-messages";
import { messages as notificationsMessages } from "~/notifications/components/i18n-messages";
import { createAddEditLinkLabelText, createAddLinkLabelText } from "~/i18n-messages";
import { PersonAffiliationsFlatList } from "../../component/person-affiliations-flat-list";
import AgvanceIntegration from "../../component/agvance-integration";
//import ConnectionName from "../../../../admin/setup/account/connection-name-list";
import ConnectionType from "../../account/connection-type-list";
import { PhotoSimple } from "~/admin/agBytes/components/photo-simple";
import { errorCodeMessages } from "~/i18n-error-messages";
import { AccountAPI } from "@ai360/core";
// Components
import {
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    DraggableList,
    NoLink,
    RadioButton,
    RadioButtonGroup,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import Address from "../../component/address/address";
import Email, { defaultValue } from "../../component/email";
import Phone from "../../component/phone/phone";
import Urls from "../../component/urls/urls";

// Style
import "./add-edit-panel.css";
import "../../add-edit-shared-responsive.css";

import { DraggableIcon, PencilIcon, TrashcanIcon } from "~/core/icons";

//CONSTANTS
const ADDRESS_TYPE_GUID = "addressTypeGuid";
const COUNTRY_GUID = "countryGuid";
const ORG_LEVEL_GUID = "orgLevelGuid";
const ORG_LEVEL_NAME = "orgLevelName";
const DEFAULT = "default";
const DEFAULT_THEME_ID = "1";
const DEFAULT_UNIT_ID = "3";
const GRASS = "grass";
const ILLINI = "illini";
const MUD = "mud";
const SKY = "sky";
const STATE_GUID = "stateGuid";
const SVG = "svg";
const onsiteEditMode = {
    ADD: "add",
    DELETE: "delete",
};
const REGISTERED_STATUSES = ["Registered", "New Registration.", "Registration Exists."];

export class AddEditPanel extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object.isRequired,
        apiErrors: PropTypes.array,
        apiErrorModel: PropTypes.object,
        clearUrlForRegistration: PropTypes.func,
        fetchData: PropTypes.bool,
        fetchUrlForRegistration: PropTypes.func,
        getOnsiteConnections: PropTypes.func,
        fetchAccount: PropTypes.func,
        fetchDropdownData: PropTypes.func,
        fetchPicklistData: PropTypes.func,
        fetchRecord: PropTypes.func,
        fetchUnitData: PropTypes.func,
        getNextId: PropTypes.func,
        getOrgLevelWithAgvanceDatabaseId: PropTypes.func,
        importExportHeaderTitle: PropTypes.func,
        intl: intlShape.isRequired,
        liftRecordData: PropTypes.func,
        mode: PropTypes.string,
        needs: PropTypes.func,
        nextId: PropTypes.number,
        record: CustomPropTypes.owner,
        recordGuid: PropTypes.string,
        registrationUrl: PropTypes.string,
        requestIds: PropTypes.object,
        saveAccount: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        userGuid: PropTypes.string,
        userRole: PropTypes.object,
        onsiteConnections: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.accountSaveRequestId = null;
        this.editData = null;
        this.fetchUrlRequestId = null;
        this.ownerData = {};
        this.state = {
            [PICKLIST_PHONE_TYPE]: [],
            [PICKLIST_UNIT_SYSTEM]: [],
            [model.PROPS_ADDRESS_TYPE_LIST]: [],
            [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]: [],
            [model.PROPS_BRANDING_YN]: false,
            [model.PROPS_COMPANY_LOGO]: "",
            [model.PROPS_COUNTRIES_LIST]: [],
            [model.PROPS_DATA_POOLING]: FALSE,
            [model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]: FALSE,
            [model.PROPS_ENROLLMENT_REQUIRED]: false,
            [model.PROPS_ORG_LEVEL_ADDRESS_LIST]: [],
            [model.PROPS_ORG_LEVEL_URL_LIST]: [],
            [model.PROPS_ORG_TYPE_LIST]: [],
            [model.PROPS_PERSON_ORG_LEVEL_LIST]: [],
            [model.PROPS_SYSTEM_LOGO]: "",
            [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: [],
            [model.PROPS_THEME_LIST]: [],
            [model.PROPS_PERSONALITIES_LIST]: [],
            currentLevel: {},
            deleteLevelIndex: null,
            disableAddLevelLink: true,
            editLevelIndex: null,
            isLevelModalOpen: false,
            isLevelNameDuplicate: false,
            levelEditMode: false,
            origLevelList: [],
            showConfirmDeleteModal: false,
            unitSystemId: DEFAULT_UNIT_ID,
            showDuplicateOrgLevels: false,
            duplicateDatabaseIdOrgLevels: [],
            onsiteConnections: [],
        };
    }
    componentDidMount() {
        const { needs, addEditPanel } = this.props;
        addEditPanel.mode === ADD && this.props.setBreadcrumbs([""]);
        this.ownerData = {
            ...service.getDefaultRecord(),
            [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]: [defaultValue()],
            [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: [defaultValue()],
        };
        needs([this.props.fetchPicklistData(), this.props.fetchDropdownData()]);
        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this._processFetchUrlRequest(nextProps);
        this._processSaveAccountRequest(nextProps);

        if (nextProps.fetchData) {
            this.props.liftRecordData(this.ownerData);
        }
        if (nextProps.addEditPanel.mode === ADD) {
            if (nextProps.nextId) {
                this.setState({
                    nextId: nextProps.nextId,
                });
            }
            if (
                this.state[PICKLIST_UNIT_SYSTEM].length &&
                this.ownerData[model.PROPS_UNIT_SYSTEM_GUID] === ""
            ) {
                const defaultUnitSystem = this.state[PICKLIST_UNIT_SYSTEM].find(
                    (item) => item.value.id === DEFAULT_UNIT_ID
                );
                this.ownerData[model.PROPS_UNIT_SYSTEM_GUID] = defaultUnitSystem.value.guid || "";
                this.setState({
                    unitSystemId: DEFAULT_UNIT_ID,
                });
            }
            if (
                this.state[model.PROPS_THEME_LIST] &&
                this.state[model.PROPS_THEME_LIST].length > 0
            ) {
                this.setDefaultTheme();
            }
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                const { agBytesAdminEmailList, testResultIssuesEmailList } = nextProps.record;
                const newAgBytesAdminEmailList =
                    agBytesAdminEmailList && agBytesAdminEmailList.length
                        ? agBytesAdminEmailList
                        : [defaultValue()];
                const newTestResultIssuesEmailList =
                    testResultIssuesEmailList && testResultIssuesEmailList.length
                        ? testResultIssuesEmailList
                        : [defaultValue()];
                const modifiedOwnerData = {
                    ...nextProps.record,
                    [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]: newAgBytesAdminEmailList,
                    [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]: newTestResultIssuesEmailList,
                };
                this.ownerData = { ...this.ownerData, ...modifiedOwnerData };
                this.props.needs([
                    this.props.getOrgLevelWithAgvanceDatabaseId(
                        this.ownerData[model.PROPS_COMPANY_ID]
                    ),
                ]);
                this.setState({
                    [model.PROPS_ADDRESS_TYPE_LIST]:
                        nextProps.record[model.PROPS_ADDRESS_TYPE_LIST],
                    [model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]:
                        nextProps.record[model.PROPS_AGBYTES_ADMIN_EMAIL_LIST],
                    [model.PROPS_BRANDING_YN]:
                        nextProps.record[model.PROPS_SYSTEM_SETTINGS][model.PROPS_BRANDING_YN],
                    [model.PROPS_DATA_POOLING]: nextProps.record[model.PROPS_DATA_POOLING]
                        ? TRUE
                        : FALSE,
                    [model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]: nextProps.record[
                        model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT
                    ]
                        ? TRUE
                        : FALSE,
                    [model.PROPS_ENROLLMENT_REQUIRED]:
                        nextProps.record[model.PROPS_ENROLLMENT_REQUIRED],
                    [model.PROPS_ORG_LEVEL_ADDRESS_LIST]:
                        nextProps.record[model.PROPS_ORG_LEVEL_ADDRESS_LIST],
                    [model.PROPS_ORG_LEVEL_URL_LIST]:
                        nextProps.record[model.PROPS_ORG_LEVEL_URL_LIST],
                    [model.PROPS_ORG_TYPE_LIST]: nextProps.record[model.PROPS_ORG_TYPE_LIST] || [],
                    [model.PROPS_PERSON_ORG_LEVEL_LIST]:
                        nextProps.record[model.PROPS_PERSON_ORG_LEVEL_LIST],
                    [model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]:
                        nextProps.record[model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST],
                    origLevelList: nextProps.record[model.PROPS_ORG_TYPE_LIST] || [],
                });
            }
        }
        this._initializeDropdowns(nextProps);
    }

    _initializeDropdowns = (nextProps) => {
        this._initializeAddressType(nextProps);
        this._initializeCountryList(nextProps);
        this._initializePhone(nextProps);
        this._initializeStateList(nextProps);
        this._initializeThemeList(nextProps);
        this._initializeUnitSystem(nextProps);
        this._initializePersonalitiesList(nextProps);
        this._initializeOwnerAdminUserList(nextProps);
        this._initializeDuplicateOrgLevels(nextProps);
    };

    _initializeDuplicateOrgLevels = (nextProps) => {
        if (nextProps.duplicateDatabaseIdOrgLevels !== this.state.duplicateDatabaseIdOrgLevels) {
            this.setState({
                duplicateDatabaseIdOrgLevels: prepareSelectableOptions(
                    nextProps.duplicateDatabaseIdOrgLevels,
                    { guid: ORG_LEVEL_GUID, label: ORG_LEVEL_NAME }
                ),
            });
        }
    };

    _initializeAddressType = (nextProps) => {
        if (
            nextProps[model.PROPS_ADDRESS_TYPE_LIST] !== this.state[model.PROPS_ADDRESS_TYPE_LIST]
        ) {
            this.setState({
                [model.PROPS_ADDRESS_TYPE_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_ADDRESS_TYPE_LIST],
                    { guid: ADDRESS_TYPE_GUID, label: NAME }
                ),
            });
        }
    };

    _initializeCountryList = (nextProps) => {
        if (nextProps[model.PROPS_COUNTRIES_LIST] !== this.state[model.PROPS_COUNTRIES_LIST]) {
            this.setState({
                [model.PROPS_COUNTRIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_COUNTRIES_LIST],
                    { guid: COUNTRY_GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    _initializePhone = (nextProps) => {
        if (nextProps[PICKLIST_PHONE_TYPE] !== this.state[PICKLIST_PHONE_TYPE]) {
            this.setState({
                [PICKLIST_PHONE_TYPE]: prepareSelectableOptions(nextProps[PICKLIST_PHONE_TYPE], {
                    guid: PICKLIST_GUID,
                    label: VALUE,
                }),
            });
        }
    };

    _initializeStateList = (nextProps) => {
        if (nextProps[model.PROPS_STATES_LIST] !== this.state[model.PROPS_STATES_LIST]) {
            this.setState({
                [model.PROPS_STATES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_STATES_LIST],
                    { guid: STATE_GUID, label: ABBREVIATION }
                ),
            });
        }
    };

    _initializeThemeList = (nextProps) => {
        if (nextProps[model.PROPS_THEME_LIST] !== this.state[model.PROPS_THEME_LIST]) {
            this.setState({
                [model.PROPS_THEME_LIST]: nextProps[model.PROPS_THEME_LIST],
            });
        }
    };

    _initializeUnitSystem = (nextProps) => {
        if (
            nextProps[PICKLIST_UNIT_SYSTEM] &&
            nextProps[PICKLIST_UNIT_SYSTEM] !== this.state[PICKLIST_UNIT_SYSTEM]
        ) {
            this.setState(
                {
                    [PICKLIST_UNIT_SYSTEM]: prepareSelectableOptions(
                        nextProps[PICKLIST_UNIT_SYSTEM],
                        { guid: PICKLIST_GUID, label: VALUE, id: ID }
                    ),
                },
                () => {
                    if (nextProps.record && nextProps.record[model.PROPS_UNIT_SYSTEM_GUID] !== "") {
                        this.setState({
                            unitSystemId: this.getID(
                                this.state[PICKLIST_UNIT_SYSTEM],
                                nextProps.record[model.PICKLIST_UNIT_SYSTEM_GUID]
                            ),
                        });
                    }
                }
            );
        }
    };

    _initializePersonalitiesList = (nextProps) => {
        if (
            nextProps[model.PROPS_PERSONALITIES_LIST] !== this.state[model.PROPS_PERSONALITIES_LIST]
        ) {
            this.setState({
                [model.PROPS_PERSONALITIES_LIST]: prepareSelectableOptions(
                    nextProps[model.PROPS_PERSONALITIES_LIST],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    _initializeOwnerAdminUserList = (nextProps) => {
        if (
            nextProps[model.PROPS_OWNER_ADMIN_USER_LIST] !==
            this.state[model.PROPS_OWNER_ADMIN_USER_LIST]
        ) {
            this.setState({
                [model.PROPS_OWNER_ADMIN_USER_LIST]: nextProps[
                    model.PROPS_OWNER_ADMIN_USER_LIST
                ].map(({ userGuid, loginEmail }) => ({
                    value: userGuid,
                    label: loginEmail,
                })),
            });
        }
    };

    createNewLevelObj = () => {
        this.setState({
            currentLevel: {
                activeYn: true,
                canDelete: true,
                companyGuid: "",
                level: 0,
                name: "",
                orgTypeGuid: "",
            },
        });
    };

    getID = (options = [], guid) => {
        if (options.length && guid) {
            const selectedOption = options.filter((option) => {
                return guid === option.value[GUID];
            });
            const { id } = selectedOption.length && selectedOption[0].value;
            return id;
        }
        return null;
    };

    getThemeClassname = (id) => {
        switch (id) {
            case "5":
                return ILLINI;
            case "4":
                return MUD;
            case "3":
                return GRASS;
            case "2":
                return SKY;
            case "1":
            default:
                return DEFAULT;
        }
    };

    mapSelectedCountry = ({ options = [], guid }) => {
        if (guid && options.length) {
            return mapToPicklistValue({ options, selectedGuid: guid });
        }
        return null;
    };

    onCancel = () => {
        if (this.state.isLevelModalOpen) {
            this.setState(
                {
                    [model.PROPS_ORG_TYPE_LIST]: this.state.origLevelList,
                },
                this.toggleLevelDialogBox()
            );
        }
    };

    onChildComponentChange = (childKey, value) => {
        this.ownerData[childKey] = value;
    };

    onLevelChange = (newProps) => {
        this.setState({
            currentLevel: {
                ...this.state.currentLevel,
                ...newProps,
            },
        });
    };

    onSave = () => {
        this.onTextChange(model.PROPS_ORG_TYPE_LIST, this.state[model.PROPS_ORG_TYPE_LIST]);
        this.setState(
            {
                origLevelList: this.state[model.PROPS_ORG_TYPE_LIST],
            },
            this.toggleLevelDialogBox()
        );
    };

    onSystemPropTextChange = (parentKey, formKey, value, callback) => {
        this.ownerData = onTextChangeMultiProp(
            this.ownerData,
            { parentKey: parentKey, formKey: formKey, value },
            callback
        );
    };

    _setEditData = (editData) => {
        if (this.editData !== editData) {
            this.editData = editData;
        }
    };

    onTextChange = (formKey, value, callback) => {
        if (formKey === model.PROPS_AGVANCE_DATABASE_ID && !value) {
            this.setState({
                showDuplicateOrgLevels: true,
            });
        }
        this.ownerData = onTextChange(this.ownerData, { formKey: [formKey], value }, callback);
    };

    resetBrandingProperties = () => {
        if (!this.state[model.PROPS_BRANDING_YN]) {
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_OWNER_BRANDING_NAME,
                ""
            );
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_PRIVACY_POLICY_TITLE,
                ""
            );
            this.onSystemPropTextChange(model.PROPS_SYSTEM_SETTINGS, model.PROPS_SYSTEM_LOGO, "");
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_POLICY_LAST_UPDATED,
                ""
            );
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_PRIVACY_POLICY_TEXT,
                ""
            );
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_SYSTEM_LOGO_DARK,
                ""
            );
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_SYSTEM_LOGO_LIGHT,
                ""
            );
            this.onSystemPropTextChange(
                model.PROPS_SYSTEM_SETTINGS,
                model.PROPS_SYSTEM_FAVICON,
                ""
            );
        }
    };

    setDefaultTheme = () => {
        const defaultTheme = this.state[model.PROPS_THEME_LIST].find((theme) => {
            return theme.id === DEFAULT_THEME_ID;
        });

        if (defaultTheme) {
            this.onTextChange(model.PROPS_WEB_UI_THEME_GUID, defaultTheme.uiThemeGuid);
        }
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.ownerData[model.PROPS_ORG_LEVEL_NAME]]);
        this.setState({
            disableAddLevelLink: this.ownerData[model.PROPS_ORG_LEVEL_NAME].length === 0,
        });
    };

    toggleDisplayBranding = () => {
        if (
            this.ownerData[model.PROPS_SYSTEM_SETTINGS][model.PROPS_BRANDING_YN] !==
            this.state[model.PROPS_BRANDING_YN]
        ) {
            this.setState(
                {
                    [model.PROPS_BRANDING_YN]:
                        this.ownerData[model.PROPS_SYSTEM_SETTINGS][model.PROPS_BRANDING_YN],
                },
                this.resetBrandingProperties
            );
        }
    };

    toggleLevelDialogBox = () => {
        this.setState({
            isLevelModalOpen: !this.state.isLevelModalOpen,
            levelEditMode: false,
            editLevelIndex: null,
        });
        this.createNewLevelObj();
    };

    _onAddConnectionType = (isEdit) => {
        //get url and present modal
        this.fetchUrlRequestId = this.props.needs([
            this.props.fetchUrlForRegistration({ ...this.editData, isEdit }),
        ]);
        this._onSaveAccount(this.editData, onsiteEditMode.ADD);
    };

    _onSaveAccount = async (record, editMode) => {
        record.companyGuid = this.ownerData.companyGuid;
        record.userGuid = this.props.userGuid;
        const onsiteConnection = {
            userGuid: this.props.userGuid,
            companyGuid: this.ownerData.companyGuid,
            onsiteConnection: record,
        };
        console.warn(onsiteConnection);
        console.warn(this.accountSaveRequestId);
        if (editMode == onsiteEditMode.ADD) {
            // await AccountAPI.addCompanyOnsiteConnection({
            //     UserGuid: this.props.userGuid,
            //     CompanyGuid: this.ownerData.companyGuid,
            //     Model: {
            //         UserGuid: this.props.userGuid,
            //         CompanyGuid: this.ownerData.companyGuid,
            //         OnsiteUserKey: null,
            //         onsiteConnection: record
            //     }
            // })
            if (this.accountSaveRequestId === null) {
                this.accountSaveRequestId = this.props.needs([
                    this.props.saveAccount({ onsiteConnection, editMode }),
                ]);
            }
            // if (this.accountSaveRequestId === null) {
            //     this.accountSaveRequestId = this.props.needs([
            //         this.props.saveAccount({ onsiteConnection, editMode }),
            //     ]);
            // }
        } else if (editMode == onsiteEditMode.DELETE) {
            await AccountAPI.deleteCompanyOnsiteConnection({
                UserGuid: this.props.userGuid,
                CompanyGuid: this.ownerData.companyGuid,
                Model: {
                    UserGuid: this.props.userGuid,
                    CompanyGuid: this.ownerData.companyGuid,
                    OnsiteUserKey: null,
                    onsiteConnection: record,
                },
            });
        }
    };

    _processFetchUrlRequest = (nextProps) => {
        if (this.fetchUrlRequestId !== null) {
            if (nextProps.requestIds[this.fetchUrlRequestId] === keywords.SUCCESS) {
                if (
                    this.props.registrationUrl != null &&
                    !REGISTERED_STATUSES.includes(this.props.registrationUrl)
                ) {
                    this.setState({
                        showRegistrationModal: true,
                    });
                } else if (REGISTERED_STATUSES.includes(this.props.registrationUrl)) {
                    this.props.clearUrlForRegistration();
                    this._onSaveAccount(
                        this.editData,
                        this.state.isEdit ? onsiteEditMode.UPDATE : onsiteEditMode.ADD
                    );
                }
                this.fetchUrlRequestId = null;
            } else if (nextProps.requestIds[this.fetchUrlRequestId] === keywords.FAILURE) {
                this.fetchUrlRequestId = null;
                this._fetchIinfo();
            }
        }
    };

    _processSaveAccountRequest = (nextProps) => {
        if (this.accountSaveRequestId !== null) {
            if (
                nextProps.requestIds[this.accountSaveRequestId] === keywords.SUCCESS ||
                nextProps.requestIds[this.accountSaveRequestId] === keywords.FAILURE
            ) {
                this.accountSaveRequestId = null;
                this._fetchInfo();
            }
        }
    };

    _fetchInfo = () => {
        this.setState({ isEdit: false });
        this.accountFetchRequestId = this.props.needs([this.props.fetchAccount()]);
    };

    _onRecordDeleted = (record) => {
        console.warn(record);
        this._onSaveAccount(record, onsiteEditMode.DELETE);
    };

    // Drag and drop util functions
    addOrEditLevel = () => {
        let existingList = this.state[model.PROPS_ORG_TYPE_LIST];
        const { currentLevel, levelEditMode, editLevelIndex } = this.state;
        let isExist = false;
        existingList.forEach(function (item) {
            if (item.name === currentLevel.name) {
                isExist = true;
            }
        });
        if (isExist) {
            this.setState({
                isLevelNameDuplicate: true,
            });
        } else {
            if (levelEditMode) {
                existingList.splice(editLevelIndex, 1, currentLevel);
                this.setState({
                    levelEditMode: false,
                    editLevelIndex: null,
                    [model.PROPS_ORG_TYPE_LIST]: existingList,
                });
            } else {
                existingList.push(currentLevel);
                this.setState({
                    [model.PROPS_ORG_TYPE_LIST]: existingList,
                });
            }
            this.createNewLevelObj();
        }
    };

    deleteLevelName = () => {
        const existingList = this.state[model.PROPS_ORG_TYPE_LIST];
        const deleteIndex = existingList.findIndex(
            (item) => item.name === this.state.deleteLevelIndex
        );
        existingList.splice(deleteIndex, 1);
        this.setState({
            [model.PROPS_ORG_TYPE_LIST]: existingList,
            deleteLevelIndex: null,
            showConfirmDeleteModal: false,
        });
    };

    onDragListEdit = (item, index) => {
        this.setState({
            currentLevel: item,
            levelEditMode: true,
            editLevelIndex: index,
        });
    };

    onDragListDelete = ({ name }) => {
        this.setState({
            showConfirmDeleteModal: true,
            deleteLevelIndex: name,
        });
    };

    onDragListUpdate = (newData) => {
        this.setState({
            [model.PROPS_ORG_TYPE_LIST]: newData,
        });
    };

    getLogoErrorClassName = (errorCodes = []) => {
        const { apiErrors } = this.props;
        return getAgBytesErrorClassNames(errorCodes, apiErrors).length
            ? "photo-simple-error"
            : null;
    };

    _renderDraggableListRow = (item, index) => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="draggable-list-row" key={index}>
                <DraggableIcon className="draggable-icon" />
                <div className="draggable-text">
                    <span title={item.name}>{item.name}</span>
                </div>
                <Checkbox
                    className="report-logo-checkbox"
                    onChange={(e, value) => {
                        const tempVal = this.state[model.PROPS_ORG_TYPE_LIST].map((ol, idx) => {
                            if (idx === index) {
                                return {
                                    ...ol,
                                    [model.PROPS_CAN_EDIT_REPORT_LOGO]: value,
                                };
                            }
                            return ol;
                        });
                        this.onDragListUpdate(tempVal);
                    }}
                    value={item[model.PROPS_CAN_EDIT_REPORT_LOGO]}
                    label={formatMessage(messages.reportLogo)}
                />
                <div onClick={() => this.onDragListEdit(item, index)}>
                    <PencilIcon className="draggable-pencil-icon" />
                </div>
                {!item.canDelete ? null : (
                    <div onClick={() => this.onDragListDelete(item, index)}>
                        <TrashcanIcon className="draggable-trashcan-icon" />
                    </div>
                )}
            </div>
        );
    };

    //Render functions
    renderOwnerInfo = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.props;
        const isNotBranded =
            !this.state[model.PROPS_BRANDING_YN] || !this.ownerData[model.PROPS_SYSTEM_SETTINGS];
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <TextInput
                            required
                            maxLength={50}
                            containerClassNames={[getAgBytesErrorClassNames([24, 2025], apiErrors)]}
                            placeholderText={formatMessage(messages.ownerName)}
                            labelText={formatMessage(messages.ownerName)}
                            value={this.ownerData[model.PROPS_ORG_LEVEL_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_ORG_LEVEL_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                        {this.props.addEditPanel.mode !== ADD ? null : (
                            <TextInput
                                required
                                maxLength={50}
                                containerClassNames={[
                                    getAgBytesErrorClassNames([91, 2052, 2093], apiErrors),
                                ]}
                                placeholderText={formatMessage(messages.adminLoginEmail)}
                                labelText={formatMessage(messages.adminLoginEmail)}
                                value={this.ownerData[model.PROPS_ADMIN_LOGIN]}
                                onChange={(value) =>
                                    this.onTextChange(model.PROPS_ADMIN_LOGIN, value)
                                }
                            />
                        )}
                        {this.props.addEditPanel.mode === ADD ||
                        !this.ownerData[model.PROPS_SYSTEM_SETTINGS] ? null : ( // && this.props.addEditPanel.mode !== ADD
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onSystemPropTextChange(
                                        model.PROPS_SYSTEM_SETTINGS,
                                        model.PROPS_BRANDING_YN,
                                        value,
                                        this.toggleDisplayBranding
                                    )
                                }
                                value={
                                    this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                        model.PROPS_BRANDING_YN
                                    ]
                                }
                                label={formatMessage(messages.allowBranding)}
                            />
                        )}
                    </SubSection>
                    <SubSection>
                        <NoLink
                            className="add-edit-levels-link"
                            label={createAddEditLinkLabelText(formatMessage, messages.level, {
                                count: 2,
                            })}
                            onClick={() => {
                                this.toggleLevelDialogBox();
                            }}
                            disabled={this.state.disableAddLevelLink}
                        />
                        <DialogBox
                            className="add-edit-levels-modal"
                            forceOverflow
                            isOpen={this.state.isLevelModalOpen}
                            isModal={true}
                            title={formatMessage(messages.locationStructure)}
                            draggable={true}
                            hideCloseX={true}
                            actionDisabled={false}
                            onAction={() => this.onSave()}
                            onClose={() => this.onCancel()}
                            footerType={DialogBoxFooterType.ACTION_CANCEL}
                            unrestricted={true}
                        >
                            <Section>
                                <SubSection className="add-level-sub-section">
                                    <NoLink
                                        className="link-add-level"
                                        label={
                                            this.state.levelEditMode
                                                ? formatMessage(messages.editLevel)
                                                : createAddLinkLabelText(
                                                      formatMessage,
                                                      messages.level
                                                  )
                                        }
                                        onClick={() => this.addOrEditLevel()}
                                        disabled={!this.state.currentLevel.name}
                                    />
                                    {!this.state.currentLevel ? null : (
                                        <TextInput
                                            maxLength={50}
                                            containerClassNames={["level-name-input"]}
                                            placeholderText={formatMessage(messages.levelName)}
                                            labelText={formatMessage(messages.levelName)}
                                            value={this.state.currentLevel.name}
                                            onChange={(value) =>
                                                this.onLevelChange({
                                                    name: value,
                                                })
                                            }
                                            clearable
                                        />
                                    )}
                                </SubSection>
                            </Section>
                            <DraggableList
                                data={this.state[model.PROPS_ORG_TYPE_LIST]}
                                onEditItem={this.onDragListEdit}
                                onListUpdate={this.onDragListUpdate}
                                onDeleteItem={this.onDragListDelete}
                                editMode={this.state.levelEditMode}
                                displayLabel={NAME}
                                rowRenderer={this._renderDraggableListRow}
                            />
                        </DialogBox>
                        <DialogBox
                            title={formatMessage(notificationsMessages.modalTitle)}
                            footerType={DialogBoxFooterType.NONE}
                            isOpen={this.state.isLevelNameDuplicate}
                            onClose={() => this.setState({ isLevelNameDuplicate: false })}
                        >
                            <div>{formatMessage(messages.duplicateLevelNameError)}</div>
                        </DialogBox>
                        <DialogBox
                            title={formatMessage(agBytesMessages.confirmTitle)}
                            footerType={DialogBoxFooterType.YES_NO}
                            isOpen={this.state.showConfirmDeleteModal}
                            onAction={() => this.deleteLevelName()}
                            onClose={() => this.setState({ showConfirmDeleteModal: false })}
                        >
                            <div>{formatMessage(errorCodeMessages.confirmDeleteMsg)}</div>
                        </DialogBox>
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section>
                    <SubSection>
                        {this.props.addEditPanel.mode !== ADD ||
                        !this.ownerData[model.PROPS_SYSTEM_SETTINGS] ? null : (
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onSystemPropTextChange(
                                        model.PROPS_SYSTEM_SETTINGS,
                                        model.PROPS_BRANDING_YN,
                                        value,
                                        this.toggleDisplayBranding
                                    )
                                }
                                value={
                                    this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                        model.PROPS_BRANDING_YN
                                    ]
                                }
                                label={formatMessage(messages.allowBranding)}
                            />
                        )}
                        {isNotBranded ? null : (
                            <TextInput
                                required
                                maxLength={50}
                                containerClassNames={[
                                    "only-child-responsive",
                                    getAgBytesErrorClassNames(2874, apiErrors),
                                ]}
                                placeholderText={formatMessage(messages.ownerBrandingName)}
                                labelText={formatMessage(messages.ownerBrandingName)}
                                value={
                                    this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                        model.PROPS_OWNER_BRANDING_NAME
                                    ]
                                }
                                onChange={(value) =>
                                    this.onSystemPropTextChange(
                                        model.PROPS_SYSTEM_SETTINGS,
                                        model.PROPS_OWNER_BRANDING_NAME,
                                        value
                                    )
                                }
                            />
                        )}
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                {!(this.props.userRole && this.props.userRole.systemOwner) ? null : (
                    <Section>
                        <SubSection>
                            <SelectInput
                                clearable={false}
                                labelText={formatMessage(messages.ownerPersonality)}
                                containerClassNames={[
                                    "only-child-responsive",
                                    getAgBytesErrorClassNames([885], apiErrors),
                                ]}
                                onChange={(value) => {
                                    this.ownerData[model.PROPS_OWNER_PERSONALITY_GUID] = value.guid;
                                    this.forceUpdate();
                                }}
                                options={this.state[model.PROPS_PERSONALITIES_LIST]}
                                value={mapToPicklistValue({
                                    options: this.state[model.PROPS_PERSONALITIES_LIST],
                                    selectedGuid:
                                        this.ownerData[model.PROPS_OWNER_PERSONALITY_GUID],
                                })}
                                placeholderText={formatMessage(messages.ownerPersonality)}
                                required
                            />
                        </SubSection>
                    </Section>
                )}
                <span className="no-bar section-fence"></span>
                {this.props.addEditPanel.mode === ADD ? null : (
                    <Section>
                        <SubSection>
                            <SelectInput
                                containerClassNames={["only-child-responsive"]}
                                optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                                options={this.state[model.PROPS_OWNER_ADMIN_USER_LIST]}
                                placeholderText={formatMessage(messages.ownerAdminUser)}
                                labelText={formatMessage(messages.ownerAdminUser)}
                                value={this.ownerData[model.PROPS_OWNER_ADMIN_USER_GUID]}
                                onChange={(value) => {
                                    this.ownerData[model.PROPS_OWNER_ADMIN_USER_GUID] = value;
                                }}
                                clearable={false}
                                required
                            />
                        </SubSection>
                    </Section>
                )}
            </div>
        );
    };

    renderFirstColumn = () => {
        const { formatMessage } = this.props.intl;
        const { state, mapSelectedCountry } = this;
        const { addEditPanel, needs, apiErrors } = this.props;
        const props = {
            apiErrors,
            mapSelectedCountry,
            addEditPanel,
            needs,
        };
        const isNotBranded =
            !this.state[model.PROPS_BRANDING_YN] || !this.ownerData[model.PROPS_SYSTEM_SETTINGS];
        const jdConnectionType = [
            {
                label: "MyJohnDeere",
                value: {
                    activeYn: true,
                    details: [],
                    guid: "180ba215-88cb-471b-99f9-dd86b856a77d",
                    id: null,
                    name: "MyJohnDeere",
                },
            },
        ];
        return (
            <div>
                <Section className="address-header">
                    <Address
                        addressList={this.state[model.PROPS_ORG_LEVEL_ADDRESS_LIST]}
                        addressTypeList={state[model.PROPS_ADDRESS_TYPE_LIST]}
                        countriesList={state[model.PROPS_COUNTRIES_LIST]}
                        stateList={state[model.PROPS_STATES_LIST]}
                        onChildComponentChange={this.onTextChange}
                        addressPropKey={model.PROPS_ORG_LEVEL_ADDRESS_LIST}
                        {...props}
                    />
                </Section>
                <Section required headerText={formatMessage(messages.responseEmail)}>
                    <SubSection>
                        <TextInput
                            required
                            maxLength={254}
                            containerClassNames={[
                                "owner-email-input",
                                getAgBytesErrorClassNames([644, 2839], apiErrors),
                            ]}
                            placeholderText={formatMessage(messages.email)}
                            labelText={formatMessage(messages.email)}
                            value={this.ownerData[model.PROPS_RESPONSE_FROM_EMAIL]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_RESPONSE_FROM_EMAIL, value)
                            }
                        />
                    </SubSection>
                </Section>
                {isNotBranded ? null : (
                    <Section required headerText={formatMessage(messages.ownerBrandingReportLogo)}>
                        <SubSection>
                            <PhotoSimple
                                className={this.getLogoErrorClassName([2873])}
                                record={
                                    this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                        model.PROPS_SYSTEM_LOGO
                                    ]
                                }
                                formatMessage={formatMessage}
                                itemListAlias={model.PROPS_SYSTEM_LOGO}
                                onChange={(value) => {
                                    this.onSystemPropTextChange(
                                        [model.PROPS_SYSTEM_SETTINGS],
                                        [model.PROPS_SYSTEM_LOGO],
                                        value
                                    );
                                }}
                                title={formatMessage(messages.recommendedImageSize)}
                                id={model.PROPS_SYSTEM_LOGO}
                                fileType={"bmp"}
                            />
                        </SubSection>
                    </Section>
                )}
                <Section required headerText={formatMessage(messages.ownerReportLogo)}>
                    <SubSection>
                        <PhotoSimple
                            className={this.getLogoErrorClassName([2875])}
                            record={this.ownerData[model.PROPS_COMPANY_LOGO]}
                            formatMessage={formatMessage}
                            itemListAlias={model.PROPS_COMPANY_LOGO}
                            onChange={(value) => {
                                this.setState({
                                    [model.PROPS_COMPANY_LOGO]: value,
                                });
                                this.onTextChange(model.PROPS_COMPANY_LOGO, value);
                            }}
                            title={formatMessage(messages.recommendedImageSize)}
                            id={model.PROPS_COMPANY_LOGO}
                            fileType={"bmp"}
                        />
                    </SubSection>
                </Section>
                <Section
                    className="owner-telematics-header"
                    headerText={formatMessage(messages.telematicsConnection)}
                >
                    <ConnectionType
                        formatMessage={formatMessage}
                        itemListAlias={this.props.onsiteConnections.onsiteConnectionsList}
                        needs={this.props.needs}
                        onAddOrEditConnectionType={(isEdit) => {
                            this._onAddConnectionType(isEdit);
                        }}
                        onTextChange={onTextChange}
                        options={jdConnectionType}
                        record={this.props.onsiteConnections.onsiteConnectionsList}
                        setEditData={this._setEditData}
                        onRecordDeleted={this._onRecordDeleted}
                        userRole={this.props.userRole}
                        hideEdit={true}
                        hideDateInput={true}
                        hideDelete={false}
                        hideAddLink={this.props.onsiteConnections.onsiteConnectionsList.length >= 1}
                    />
                </Section>
            </div>
        );
    };

    renderSecondColumn = () => {
        const { formatMessage } = this.props.intl;
        const { addEditPanel, apiErrorModel, apiErrors } = this.props;
        const props = { addEditPanel, apiErrors, apiErrorModel };
        const isNotBranded =
            !this.state[model.PROPS_BRANDING_YN] || !this.ownerData[model.PROPS_SYSTEM_SETTINGS];
        return (
            <div>
                <Email
                    required
                    defaultValue={[defaultValue()]}
                    emailErrorCodeList={[704, 2827]}
                    emailAlias={model.PROPS_EMAIL_ADDRESS}
                    emailPropKey={model.PROPS_AGBYTES_ADMIN_EMAIL_LIST}
                    emailHeaderText={messages.agBytesAdminEmail}
                    emailList={this.ownerData[model.PROPS_AGBYTES_ADMIN_EMAIL_LIST]}
                    onChildComponentChange={this.onTextChange}
                    {...props}
                />
                <Email
                    defaultValue={[defaultValue()]}
                    emailErrorCodeList={[838, 2830]}
                    emailHeaderText={messages.automatedTestResultEmail}
                    emailList={this.ownerData[model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST]}
                    emailAlias={model.PROPS_EMAIL_ADDRESS}
                    emailPropKey={model.PROPS_TEST_RESULT_ISSUES_EMAIL_LIST}
                    onChildComponentChange={this.onTextChange}
                    {...props}
                />
                <Section headerText={formatMessage(messages.sendResultsToEmail)}>
                    <SubSection>
                        <TextInput
                            maxLength={254}
                            containerClassNames={[
                                "owner-email-input",
                                getAgBytesErrorClassNames(2840, apiErrors),
                            ]}
                            placeholderText={formatMessage(messages.email)}
                            labelText={formatMessage(messages.email)}
                            value={this.ownerData[model.PROPS_SEND_RESULTS_TO_EMAIL_ADDRESS]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_SEND_RESULTS_TO_EMAIL_ADDRESS, value)
                            }
                        />
                    </SubSection>
                </Section>
                <Section>
                    <SubSection>
                        <Phone
                            phoneList={this.ownerData[model.PROPS_OWNER_PHONE_NUMBER_LIST]}
                            phoneTypeList={this.state[PICKLIST_PHONE_TYPE]}
                            phonePropKey={model.PROPS_OWNER_PHONE_NUMBER_LIST}
                            onChildComponentChange={this.onTextChange}
                            {...props}
                        />
                    </SubSection>
                </Section>
                <Urls
                    urlList={this.state[model.PROPS_ORG_LEVEL_URL_LIST]}
                    urlListAlias={model.PROPS_ORG_LEVEL_URL_LIST}
                    onChildComponentChange={this.onChildComponentChange}
                    {...props}
                />
                {isNotBranded ? null : (
                    <div>
                        <Section headerText={formatMessage(messages.systemLogoDarkTheme)}>
                            <SubSection>
                                <PhotoSimple
                                    className={this.getLogoErrorClassName([2869])}
                                    record={
                                        this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                            model.PROPS_SYSTEM_LOGO_DARK
                                        ]
                                    }
                                    formatMessage={formatMessage}
                                    itemListAlias={model.PROPS_SYSTEM_LOGO_DARK}
                                    onChange={(value) => {
                                        this.onSystemPropTextChange(
                                            [model.PROPS_SYSTEM_SETTINGS],
                                            [model.PROPS_SYSTEM_LOGO_DARK],
                                            value
                                        );
                                    }}
                                    title={formatMessage(messages.recommendedImageSize)}
                                    id={model.PROPS_SYSTEM_LOGO_DARK}
                                    fileType={SVG}
                                />
                            </SubSection>
                        </Section>
                        <Section headerText={formatMessage(messages.systemLogoLightTheme)}>
                            <SubSection>
                                <PhotoSimple
                                    className={this.getLogoErrorClassName([2870])}
                                    record={
                                        this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                            model.PROPS_SYSTEM_LOGO_LIGHT
                                        ]
                                    }
                                    formatMessage={formatMessage}
                                    itemListAlias={model.PROPS_SYSTEM_LOGO_LIGHT}
                                    onChange={(value) => {
                                        this.onSystemPropTextChange(
                                            [model.PROPS_SYSTEM_SETTINGS],
                                            [model.PROPS_SYSTEM_LOGO_LIGHT],
                                            value
                                        );
                                    }}
                                    title={formatMessage(messages.recommendedImageSize)}
                                    id={model.PROPS_SYSTEM_LOGO_LIGHT}
                                    fileType={SVG}
                                />
                            </SubSection>
                        </Section>
                        <Section headerText={formatMessage(messages.browserTabIcon)}>
                            <PhotoSimple
                                className="favicon"
                                formatMessage={formatMessage}
                                itemListAlias={model.PROPS_SYSTEM_FAVICON}
                                onChange={(value) =>
                                    this.onSystemPropTextChange(
                                        [model.PROPS_SYSTEM_SETTINGS],
                                        model.PROPS_SYSTEM_FAVICON,
                                        value
                                    )
                                }
                                record={
                                    this.ownerData[model.PROPS_SYSTEM_SETTINGS][
                                        model.PROPS_SYSTEM_FAVICON
                                    ]
                                }
                                title={formatMessage(messages.recommendedFaviconSize)}
                                fileType={"ico"}
                                id={model.PROPS_SYSTEM_FAVICON}
                            />
                        </Section>
                    </div>
                )}
            </div>
        );
    };
    renderThirdColumn = () => {
        const { formatMessage } = this.props.intl;
        const { apiErrors } = this.props;
        const personalityLabel = !this.ownerData[model.PROPS_OWNER_PERSONALITY_GUID]
            ? null
            : this.getID(
                  this.state[model.PROPS_PERSONALITIES_LIST],
                  this.ownerData[model.PROPS_OWNER_PERSONALITY_GUID]
              );
        const lockCustomersDisabled = this.state[model.PROPS_ENROLLMENT_REQUIRED] === false;
        return (
            <div>
                <Section headerText={formatMessage(messages.communityData)}>
                    <SubSection>
                        <div className="community-data-container community-customer">
                            <div className="community-data-label">
                                {formatMessage(messages.customerDataPoolLabel)}
                            </div>
                            <RadioButtonGroup
                                selectedValue={
                                    this.state[model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]
                                }
                                afterOnChange={(value) => {
                                    const valueBool = value === TRUE;
                                    this.onTextChange(
                                        model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT,
                                        valueBool
                                    );
                                    this.setState({
                                        [model.PROPS_DATA_POOLING_CUSTOMER_DEFAULT]: value,
                                    });
                                }}
                            >
                                <RadioButton value={TRUE} label={formatMessage(messages.yes)} />
                                <RadioButton value={FALSE} label={formatMessage(messages.no)} />
                            </RadioButtonGroup>
                        </div>
                        <div className="community-data-container community-data-pool">
                            <div className="community-data-label">
                                {formatMessage(messages.ownerDataPoolLabel)}
                            </div>
                            <RadioButtonGroup
                                selectedValue={this.state[model.PROPS_DATA_POOLING]}
                                afterOnChange={(value) => {
                                    const valueBool = value === TRUE;
                                    this.onTextChange(model.PROPS_DATA_POOLING, valueBool);
                                    this.setState({
                                        [model.PROPS_DATA_POOLING]: value,
                                    });
                                }}
                            >
                                <RadioButton value={TRUE} label={formatMessage(messages.yes)} />
                                <RadioButton value={FALSE} label={formatMessage(messages.no)} />
                            </RadioButtonGroup>
                        </div>
                        <div className="community-data-container">
                            <Checkbox
                                onChange={(e, value) => {
                                    this.onTextChange(model.PROPS_ENROLLMENT_REQUIRED, value);
                                    this.setState({
                                        [model.PROPS_ENROLLMENT_REQUIRED]: value,
                                    });
                                }}
                                value={this.state[model.PROPS_ENROLLMENT_REQUIRED]}
                                label={formatMessage(messages.enrollmentRequired)}
                            />
                            <Checkbox
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ENROLLMENT_LOCK_CUSTOMERS, value)
                                }
                                value={this.ownerData[model.PROPS_ENROLLMENT_LOCK_CUSTOMERS]}
                                label={formatMessage(messages.lockCustomersNotEnrolledYn)}
                                disabled={lockCustomersDisabled}
                            />
                        </div>
                    </SubSection>
                </Section>

                <Section required headerText={formatMessage(messages.unitSystem)}>
                    <SubSection>
                        <SelectInput
                            containerClassNames={[getAgBytesErrorClassNames(640, apiErrors)]}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[PICKLIST_UNIT_SYSTEM]}
                            placeholderText={formatMessage(messages.unitSystem)}
                            labelText={formatMessage(messages.unitSystem)}
                            value={mapToPicklistValue({
                                options: this.state[PICKLIST_UNIT_SYSTEM],
                                selectedGuid: this.ownerData[model.PROPS_UNIT_SYSTEM_GUID],
                            })}
                            onChange={(value) => {
                                this.ownerData[model.PROPS_UNIT_SYSTEM_GUID] = value.guid;
                            }}
                            clearable={false}
                            required
                        />
                    </SubSection>
                </Section>
                {!this.state[model.PROPS_THEME_LIST] ? null : (
                    <Section
                        headerText={formatMessage(messages.appColorTheme)}
                        className="theme-selector"
                    >
                        <SubSection>
                            <RadioButtonGroup
                                className="theme-control-cont"
                                selectedValue={
                                    this.ownerData[model.PROPS_WEB_UI_THEME_GUID] ||
                                    this.setDefaultTheme()
                                }
                                afterOnChange={(value) =>
                                    this.onTextChange(model.PROPS_WEB_UI_THEME_GUID, value)
                                }
                            >
                                {this.state[model.PROPS_THEME_LIST].map((item) => (
                                    <RadioButton
                                        value={item.uiThemeGuid}
                                        key={item.id}
                                        labelClassName={this.getThemeClassname(item.id)}
                                    />
                                ))}
                            </RadioButtonGroup>
                        </SubSection>
                    </Section>
                )}
                <Section
                    headerText={formatMessage(messages.notes)}
                    className="form-section-child-stretch"
                >
                    <SubSection>
                        <TextArea
                            placeholderText={formatMessage(messages.notes)}
                            labelText={formatMessage(messages.notes)}
                            value={this.ownerData[model.PROPS_NOTES]}
                            onChange={(value) => this.onTextChange(model.PROPS_NOTES, value)}
                        />
                    </SubSection>
                </Section>
                {this.state[model.PROPS_PERSON_ORG_LEVEL_LIST].length === 0 ? null : (
                    <Section className="grid-section" headerText={formatMessage(messages.person)}>
                        <PersonAffiliationsFlatList
                            record={this.state[model.PROPS_PERSON_ORG_LEVEL_LIST]}
                            statePropName={model.PROPS_STATE_ABBREVIATION}
                        />
                    </Section>
                )}

                {!(
                    this.ownerData[model.PROPS_OWNER_PERSONALITY_GUID] &&
                    personalityLabel !== PersonalityTypes.DISCONNECTED.toString()
                ) ? null : (
                    <div>
                        <AgvanceIntegration
                            aliasList={{
                                apiUrl: model.PROPS_AGVANCE_API_URL,
                                databaseId: model.PROPS_AGVANCE_DATABASE_ID,
                                userId: model.PROPS_AGVANCE_USER_ID,
                                userPassword: model.PROPS_AGVANCE_USER_PASSWORD,
                                requireCustomerSearchFlag: model.PROPS_REQUIRE_CUSTOMER_SEARCH,
                            }}
                            record={this.ownerData}
                            onTextChange={this.onTextChange}
                            isAgvancePersonality={
                                personalityLabel === PersonalityTypes.AGVANCE_STANDARD.toString()
                            }
                        />
                        {!this.state.showDuplicateOrgLevels ? null : (
                            <Section>
                                <SubSection>
                                    <SelectInput
                                        clearable={false}
                                        options={this.state.duplicateDatabaseIdOrgLevels}
                                        value=""
                                        onChange={(value) => {
                                            this.onTextChange(
                                                model.PROPS_UPDATED_AGVANCE_ORG_LEVEL_GUID,
                                                value.guid
                                            );
                                        }}
                                        placeholderText={formatMessage(
                                            messages.migrateCustomerToLocation
                                        )}
                                        containerClassNames={["migrate-to-customer-select"]}
                                        required
                                    />
                                </SubSection>
                            </Section>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="add-edit-panel owner-setup-cont">
                {this.renderOwnerInfo()}
                <div className="section-container">
                    {this.renderFirstColumn()}
                    <span className="section-fence bar" />
                    {this.renderSecondColumn()}
                    <span className="section-fence bar" />
                    {this.renderThirdColumn()}
                </div>
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
