import { connect } from "react-redux";
import { withMasked, withApiResult } from "~/hocs";
import { getUser } from "~/login";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { actions } from "./data";
import * as selectors from "./data/selectors";
import { UserAccount } from "./account";

const mapStateToProps = (state) => ({
    connectionTypeList: selectors.getConnectionTypeList(state),
    registrationUrl: selectors.getUrlForRegistration(state),
    onsiteEquipmentList: selectors.getOnsiteEquipmentList(state),
    onsiteUserKey: selectors.getOnsiteUserKey(state),
    userAccount: selectors.getUserAccount(state),
    userRole: getUser(state).role,
    companyGuid: getUser(state).companyGuid,
});

const mapDispatchToProps = (dispatch) => ({
    clearUrlForRegistration: () => dispatch(actions.fetchUrlForRegistrationSuccess("")),
    fetchAccount: actions.fetchAccount,
    fetchConnectionTypeList: dispatch(actions.fetchConnectionTypeList()),
    fetchUrlForRegistration: actions.fetchUrlForRegistration,
    getOnSiteEquipmentList: actions.getOnSiteEquipmentList,
    saveAccount: actions.saveAccount,
    setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
});

export default withMasked(withApiResult(connect(mapStateToProps, mapDispatchToProps)(UserAccount)));
