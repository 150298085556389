import * as actions from "./actions";

import { ActionType } from "typesafe-actions";

const initialState: State = {
    receivers: [],
};

export interface State {
    receivers: any[];
}

export function messagingReducer(state = initialState, action: ActionType<typeof actions>): State {
    switch (action.type) {
        case actions.NEW_RECEIVER: {
            return {
                ...state,
                receivers: state.receivers.concat(action.payload.receiver),
            };
        }
        default:
            return state;
    }
}
