import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { DialogBox, DialogBoxFooterType, Loader, LoaderTypes, TextArea, TextInput } from "~/core";
import { actions as notificationActions } from "~/notifications";
import "../rec-module.css";
import { messages } from "../i18n-messages";
import * as selectors from "../../selectors";
import { IFormatter } from "@ai360/core";

interface ISendRecValidationProps {
    isOpen: boolean;
    onSend: (evt: { workOrder: string; instructions: string }) => void;
    onClose: () => void;
    onApiError: (err, message) => void;
    intl: IFormatter;
    sendDestination: string;
    isLoading: boolean;
    userGuid: string;
    rollingMessage: string;
}

const SendRecValidation = (props: ISendRecValidationProps): JSX.Element => {
    const { isOpen, isLoading, onSend, onClose, sendDestination, rollingMessage } = props;
    const { formatMessage } = props.intl;

    const [workOrder, setWorOrder] = useState<string>("");
    const [instructions, setInstructions] = useState<string>("");
    const [disableSend, setDisableSend] = useState<boolean>(false);
    const [validationFeedback, setValidationFeedback] = useState<string>("");
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        if (rollingMessage && showLoader) {
            setShowLoader(false);
        }
        if (rollingMessage) {
            const oldValidationFeedback = validationFeedback;
            const newValidationFeedback =
                oldValidationFeedback.length === 0
                    ? rollingMessage
                    : oldValidationFeedback + "\n" + rollingMessage;
            setValidationFeedback(newValidationFeedback);
        }
    }, [rollingMessage]);

    useEffect(() => {
        setValidationFeedback("");
    }, []);

    const onWorkPlanSend = () => {
        setShowLoader(true);
        onSend({
            workOrder,
            instructions,
        });
        setDisableSend(true);
    };

    if (!isOpen) {
        return null;
    }

    console.warn(rollingMessage);

    return (
        <DialogBox
            action="Send"
            actionDisabled={disableSend}
            className="validation-feedback-modal"
            draggable
            footerType={DialogBoxFooterType.ACTION_CLOSE}
            forceOverflow
            isOpen={isOpen}
            onAction={onWorkPlanSend}
            onClose={onClose}
            title={`${formatMessage(messages.sendRecToText)} ${sendDestination}`}
            unrestricted
        >
            {showLoader && <Loader />}
            <TextInput
                placeholderText={formatMessage(messages.workOrderText)}
                value={workOrder}
                onChange={(value) => setWorOrder(value)}
            ></TextInput>
            <TextArea
                containerClassNames={["work-instructions"]}
                placeholderText={formatMessage(messages.instructionsText)}
                value={instructions}
                onChange={(value) => setInstructions(value)}
            ></TextArea>
            <TextArea
                placeholderText={formatMessage(messages.statusMessagesText)}
                value={validationFeedback}
                readOnly
                disabled
            ></TextArea>
            {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
        </DialogBox>
    );
};

const mapStateToProps = (state) => {
    const { isLoading } = selectors.getModuleState(state);
    const rollingMessage = state["NOTIFICATION_STATE"].messageForValidationFeedback;
    return {
        isLoading,
        rollingMessage,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onApiError: (err, message) => dispatch(notificationActions.apiCallError(err, null, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SendRecValidation));
