import { defineMessages } from "react-intl";

export default defineMessages({
    clipToOtherField: {
        id: "fieldBoundaryOverlap.clipToOtherField",
        defaultMessage: "Clip to Other Field",
    },
    promptDescription: {
        id: "fieldBoundaryOverlap.promptDescription",
        defaultMessage:
            "Overlap to existing field boundary detected. How would you like to handle this overlap?",
    },
    promptOptionOneDescription: {
        id: "fieldBoundaryOverlap.promptOptionOneDescription",
        defaultMessage:
            "The Clip to Other Field button will clip this new boundary to existing Field(s).",
    },
    promptOptionTwoDescription: {
        id: "fieldBoundaryOverlap.promptOptionTwoDescription",
        defaultMessage:
            "The Update Other Field button will update all existing Field boundaries and clip those to this new boundary.",
    },
    undoOptionDescription: {
        id: "fieldBoundaryOverlap.undoOptionDescription",
        defaultMessage: "Undo can be clicked to see these options again.",
    },
    title: {
        id: "fieldBoundaryOverlap.title",
        defaultMessage: "Overlap Detected",
    },
    updateOtherField: {
        id: "fieldBoundaryOverlap.updateOtherField",
        defaultMessage: "Update Other Field",
    },
});
