import { createAction } from "redux-act";

const ADD_OWNER = "ADD_OWNER_DATA";
const ADD_OWNER_FAILED = "ADD_OWNER_FAILED";
const ADD_OWNER_SUCCESS = "ADD_OWNER_SUCCESS";

const SAVE_OWNER_TELEMATICS = "SAVE_OWNER_TELEMATICS";
const SAVE_OWNER_TELEMATICS_FAILED = "SAVE_OWNER_TELEMATICS_FAILED";
const SAVE_OWNER_TELEMATICS_SUCCESS = "SAVE_OWNER_TELEMATICS_SUCCESS";

const FETCH_OWNER_REGISTRATION_URL = "FETCH_OWNER_REGISTRATION_URL";
const FETCH_OWNER_REGISTRATION_URL_FAILED = "FETCH_OWNER_REGISTRATION_URL_FAILED";
const FETCH_OWNER_REGISTRATION_URL_SUCCESS = "FETCH_OWNER_REGISTRATION_URL_SUCCESS";

export const addOwner = createAction(ADD_OWNER);
export const addOwnerFailed = createAction(ADD_OWNER_FAILED);
export const addOwnerSuccess = createAction(ADD_OWNER_SUCCESS);

export const saveOwnerTelematics = createAction(SAVE_OWNER_TELEMATICS);
export const saveOwnerTelematicsFailed = createAction(SAVE_OWNER_TELEMATICS_FAILED);
export const saveOwnerTelematicsSuccess = createAction(SAVE_OWNER_TELEMATICS_SUCCESS);

export const fetchUrlForRegistrationOwner = createAction(FETCH_OWNER_REGISTRATION_URL);
export const fetchUrlForRegistrationOwnerFailure = createAction(
    FETCH_OWNER_REGISTRATION_URL_FAILED
);
export const fetchUrlForRegistrationOwnerSuccess = createAction(
    FETCH_OWNER_REGISTRATION_URL_SUCCESS
);
