import { connect } from "react-redux";
import { fetchPicklistData } from "~/core/picklist/actions";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { fetchUnitData } from "~/core/units/actions";
import { getModelNameToState } from "~/hocs/crud/utils";
import { setBreadcrumbs } from "~/sliding-panel/data/actions";
import { getPicklists, getUnits, getDropdowns, getRecord } from "../selectors";
import { getTheUserGuid } from "~/login/selectors";

const mapStateToProps =
    ({ service, pickLists, dropdowns = {}, unitTypes }) =>
    (state) => {
        const modelName = getModelNameToState(service.modelName);
        return {
            ...getPicklists(pickLists, state),
            ...getDropdowns(Object.keys(dropdowns), state),
            ...getUnits(unitTypes, state),
            nextId: getRecord(state, modelName, "nextId"),
            record: getRecord(state, modelName, getModelNameToState(service.modelName, "EDIT")),
            loggedInUserGuid: getTheUserGuid(state),
        };
    };

const mapDispatchToProps =
    ({ pickLists, dropdowns, unitTypes }) =>
    (dispatch, ownProps) => {
        const { actions } = ownProps;
        return {
            getNextId: (payload) => actions.getNextId(payload),
            fetchPicklistData: (payload) => fetchPicklistData(payload || pickLists),
            fetchUnitData: (payload) => fetchUnitData(payload || unitTypes),
            fetchDropdownData: (payload) => fetchDropdownData(payload || dropdowns),
            fetchRecord: (recordGuid) => dispatch(actions.fetchRecord(recordGuid)),
            setBreadcrumbs: (payload) => dispatch(setBreadcrumbs(payload)),
        };
    };

const advanceConnect = (View, params) =>
    connect(mapStateToProps(params), mapDispatchToProps(params))(View);

export default advanceConnect;
