import React from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import { EventAPI, FieldAPI } from "@ai360/core";

import { messages } from "./i18n-messages";
import { DialogBox, Checkbox, DialogBoxFooterType } from "~/core";

import { currentUserHasRole } from "~/login/selectors";
import {
    fetchResurfaceEvents,
    clearResurfaceEvents,
} from "~/action-panel/components/field-module/actions";
import {
    getResurfaceEvents,
    getOverlappingFieldBoundaries,
} from "~/action-panel/components/field-module/selectors";
import { getFieldEditsChanged } from "~/map/components/map-tools/selectors";

import "./field-resurface-prompt.css";

const INTERPOLATION_TYPE_STANDARD = "Standard";

export interface IFieldResuracePromptProps {
    boundaryChanged: boolean;
    clearResurfaceEvents: () => void;
    fetchResurfaceEvents: (guid: string, keepExistingEvents: boolean) => void;
    fieldGuid?: string;
    hasResurfacePermission: boolean;
    intl: intlShape;
    isOpen?: boolean;
    onCancel?: () => void;
    onClose?: (v: any[]) => void;
    overlappingFieldBoundaries: { [fieldGuid: string]: FieldAPI.IFieldBoundary[] };
    resurfaceEvents?: EventAPI.IResurfaceEvent[];
}

interface IFieldResurfaceListItem {
    event: EventAPI.IResurfaceEvent;
    resurface: boolean;
}

export interface IFieldResuracePromptState {
    eventList: IFieldResurfaceListItem[];
}
export class FieldResurfacePrompt_ extends React.Component<
    IFieldResuracePromptProps,
    IFieldResuracePromptState
> {
    static defaultProps = {
        isOpen: false,
        resurfaceEvents: [],
    };

    static checkIfInvalid(props: IFieldResuracePromptProps): boolean {
        return !(
            props.hasResurfacePermission &&
            Object.hasOwn(props, "fieldGuid") &&
            props.resurfaceEvents &&
            this.hasEvents(props.resurfaceEvents, props.boundaryChanged)
        );
    }

    static hasEvents = (resurfaceEvents: Record<string, any>, boundaryChanged: boolean): boolean =>
        resurfaceEvents &&
        ((boundaryChanged && resurfaceEvents.length > 0) ||
            (!boundaryChanged && resurfaceEvents.some((e) => !e.boundaryActive)));

    constructor(props: IFieldResuracePromptProps) {
        super(props);

        if (props.resurfaceEvents) {
            this.state = {
                eventList: this.props.resurfaceEvents.map((e) => {
                    return {
                        event: e,
                        resurface: e.interpolationType === INTERPOLATION_TYPE_STANDARD,
                    };
                }),
            };
        } else {
            this.state = {
                eventList: [],
            };
        }
    }

    componentDidMount(): void {
        this.updateResurfaceEvents(this.props);
        this.closeIfNotEnoughInfoToOpen(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IFieldResuracePromptProps): void {
        if (nextProps.hasResurfacePermission) {
            if (
                nextProps.fieldGuid &&
                (this.props.fieldGuid !== nextProps.fieldGuid ||
                    this.props.boundaryChanged !== nextProps.boundaryChanged)
            ) {
                this.updateResurfaceEvents(nextProps);
            }
            this.loadResurfaceEvent(nextProps);
        }
        if (!this.props.isOpen) {
            this.closeIfNotEnoughInfoToOpen(nextProps);
        }
    }

    public closeIfNotEnoughInfoToOpen(props: IFieldResuracePromptProps): void {
        if (props.isOpen && props.onClose && FieldResurfacePrompt_.checkIfInvalid(props)) {
            props.onClose(this._generateFinalObject(true));
        }
    }

    public loadResurfaceEvent(nextProps: IFieldResuracePromptProps): void {
        if (
            nextProps.fieldGuid &&
            nextProps.resurfaceEvents !== this.props.resurfaceEvents &&
            nextProps.resurfaceEvents !== null
        ) {
            this.setState({
                eventList: nextProps.resurfaceEvents.map((e) => {
                    return {
                        event: e,
                        resurface: e.interpolationType === INTERPOLATION_TYPE_STANDARD,
                    };
                }),
            });
        }
    }

    public updateResurfaceEvents(props: IFieldResuracePromptProps) {
        if (props.fieldGuid) {
            this.props.fetchResurfaceEvents(
                props.fieldGuid,
                Object.keys(props.overlappingFieldBoundaries).length > 0
            );
        }
    }

    public onCheckboxChange(guid: string, value: boolean): void {
        const { eventList } = this.state;
        eventList[eventList.findIndex((e: any) => e.event.agEventGeneralGuid === guid)].resurface =
            value;
        this.setState({ eventList });
    }

    private _generateFinalObject = (noClicked: boolean): any[] => {
        const { boundaryChanged } = this.props;
        const { eventList } = this.state;
        const resurfaceCandidates = boundaryChanged
            ? eventList
            : eventList.filter((e) => !e.event.boundaryActive);
        return noClicked
            ? []
            : resurfaceCandidates.filter((e) => e.resurface).map((e) => e.event.agEventGeneralGuid);
    };

    public areAnyEventsSelected = (eventList: Record<string, any>[]): boolean =>
        eventList.some((value) => value.resurface);

    public onButtonClicked = (noClicked: boolean): void => {
        if (this.props.onClose && this.props.isOpen) {
            this.props.onClose(this._generateFinalObject(noClicked));
        }
        this.props.clearResurfaceEvents();
    };

    public onCloseClicked = (): void => {
        if (this.props.onCancel && this.props.isOpen) {
            this.props.onCancel();
        }
    };

    private _getLabel = (row: Record<string, any>, withAcres: boolean): string => {
        const { formatDate, formatNumber } = this.props.intl;
        const { event } = row;
        let label = `${event.eventType} - ${event.croppingSeasonName} - ${formatDate(
            event.eventDate
        )}`;

        if (event.eventName && event.eventName !== "") {
            label += ` - ${event.eventName}`;
        }

        if (withAcres) {
            label += ` - ${formatNumber(event.zoneAcres, {
                maximumFractionDigits: 2,
            })} acres`;
        }

        return label;
    };

    #groupResurfaceEvents(
        eventList: IFieldResurfaceListItem[]
    ): Record<string, IFieldResurfaceListItem[]> {
        return eventList.reduce((acc, eventListItem) => {
            const fieldGuid = eventListItem.event.fieldGuid;
            acc[fieldGuid] = acc[fieldGuid] || [];
            acc[fieldGuid].push(eventListItem);
            return acc;
        }, {} as Record<string, IFieldResurfaceListItem[]>);
    }

    #farmAndFieldHeaderText(resurfaceItems: IFieldResurfaceListItem[]): string {
        if (resurfaceItems.length === 0) {
            return "";
        }
        const event = resurfaceItems[0].event;
        const headerStrings = [event.farmName, event.fieldName].filter(Boolean);
        return headerStrings.join(" - ");
    }

    renderStandardInterpolationEventList(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { eventList } = this.state;

        const standardInterpolationEvents = eventList.filter(
            (e) => e.event.interpolationType === INTERPOLATION_TYPE_STANDARD
        );
        const list = this.props.boundaryChanged
            ? standardInterpolationEvents
            : standardInterpolationEvents.filter((e) => !e.event.boundaryActive);
        const groupedEvents = this.#groupResurfaceEvents(list);

        if (list.length > 0) {
            return (
                <div className="resurfaceEventSection">
                    <div className="resurfaceSectionMessage">
                        {formatMessage(messages.standardInterpolationMessage)}
                    </div>
                    <div className="resurfaceEventList">
                        {Object.keys(groupedEvents).map((fieldGuid) => (
                            <div key={fieldGuid} className="resurfaceEventGroup">
                                <div className="resurfaceEventGroupHeader">
                                    {this.#farmAndFieldHeaderText(groupedEvents[fieldGuid])}
                                </div>
                                <div className="resurfaceEventGroupList">
                                    {groupedEvents[fieldGuid].map((row) => (
                                        <Checkbox
                                            onChange={(e, v) =>
                                                this.onCheckboxChange(
                                                    row.event.agEventGeneralGuid,
                                                    v
                                                )
                                            }
                                            value={row.resurface}
                                            label={this._getLabel(row, true)}
                                            key={row.event.agEventGeneralGuid}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderZoneInterpolationEventList(): JSX.Element {
        const { formatMessage } = this.props.intl;
        const { eventList } = this.state;

        const zoneInterpolationEvents = eventList.filter(
            (e) => e.event.interpolationType !== INTERPOLATION_TYPE_STANDARD
        );
        const list = this.props.boundaryChanged
            ? zoneInterpolationEvents
            : zoneInterpolationEvents.filter((e) => !e.event.boundaryActive);
        const groupedEvents = this.#groupResurfaceEvents(list);

        if (list.length > 0) {
            return (
                <div className="resurfaceEventSection">
                    <div className="resurfaceSectionMessage">
                        {formatMessage(messages.zoneInterpolationMessage)}
                    </div>
                    <div className="resurfaceEventList">
                        {Object.keys(groupedEvents).map((fieldGuid) => (
                            <div key={fieldGuid} className="resurfaceEventGroup">
                                <div className="resurfaceEventGroupHeader">
                                    {this.#farmAndFieldHeaderText(groupedEvents[fieldGuid])}
                                </div>
                                <div className="resurfaceEventGroupList">
                                    {groupedEvents[fieldGuid].map((row) => (
                                        <Checkbox
                                            onChange={(e, v) =>
                                                this.onCheckboxChange(
                                                    row.event.agEventGeneralGuid,
                                                    v
                                                )
                                            }
                                            value={row.resurface}
                                            label={this._getLabel(row, true)}
                                            key={row.event.agEventGeneralGuid}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        if (
            this.state.eventList === null ||
            !this.props.hasResurfacePermission ||
            !FieldResurfacePrompt_.hasEvents(this.state.eventList, this.props.boundaryChanged)
        ) {
            return null;
        } else {
            const message = this.props.boundaryChanged ? messages.prompt : messages.inactivePrompt;

            return (
                <div className="field-resurface-modal">
                    <DialogBox
                        isModal
                        unrestricted
                        closeOnEscape={false}
                        closeOnClickOff={false}
                        title={formatMessage(messages.dialogTitle)}
                        isOpen={this.props.isOpen}
                        footerType={DialogBoxFooterType.MULTI_ACTION_CANCEL}
                        multiActionList={[
                            {
                                action: "yes",
                                actionDisabled: !this.areAnyEventsSelected(this.state.eventList),
                                onAction: () => this.onButtonClicked(false),
                            },
                            {
                                action: "no",
                                onAction: () => this.onButtonClicked(true),
                            },
                        ]}
                        onClose={() => this.onCloseClicked()}
                    >
                        <div className="resurfaceMessage">{formatMessage(message)}</div>
                        {this.renderStandardInterpolationEventList()}
                        {this.renderZoneInterpolationEventList()}
                    </DialogBox>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    boundaryChanged: getFieldEditsChanged(state),
    resurfaceEvents: getResurfaceEvents(state),
    hasResurfacePermission: currentUserHasRole(state, "resurfacing"),
    overlappingFieldBoundaries: getOverlappingFieldBoundaries(state),
});

const mapDispatchToProps = (dispatch) => ({
    fetchResurfaceEvents: (fieldGuid, keepExistingEvents) =>
        dispatch(fetchResurfaceEvents(fieldGuid, keepExistingEvents)),
    clearResurfaceEvents: () => dispatch(clearResurfaceEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FieldResurfacePrompt_));
