import React, { Component } from "react";
import PropTypes from "prop-types";
import { Section, SelectInput, SubSection } from "~/core";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../i18n-messages";
import "./component.css";

class PortalIntegration extends Component {
    static propTypes = {
        intl: intlShape.isRequired,
        isEditMode: PropTypes.any,
        onOrganizationChange: PropTypes.func,
        records: PropTypes.array,
        value: PropTypes.string,
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { onOrganizationChange, records, value } = this.props;
        return (
            <Section headerText={formatMessage(messages.portalIntegration)}>
                <SubSection>
                    <SelectInput
                        containerClassNames={["portal-integration"]}
                        clearable={false}
                        placeholderText={formatMessage(messages.jdOrganizationName)}
                        value={value}
                        options={records.map(({ name, organizationId }) => ({
                            value: organizationId,
                            label: name,
                        }))}
                        onChange={(value) => onOrganizationChange(value, records)}
                    />
                </SubSection>
            </Section>
        );
    }
}

export default injectIntl(PortalIntegration);
