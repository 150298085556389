import { all, call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import fileSaver from "file-saver";
import { OrderedMap } from "immutable";

import { actions as accordionActions } from "~/accordion";
import {
    actions as layerListActions,
    selectors as layerListSelectors,
} from "~/action-panel/components/layer-module/components/layer-list";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { getTheUserGuid } from "~/login/selectors";
import { actions as notificationActions, MSGTYPE } from "~/notifications";
import { actions as messagingActions } from "~/messaging";
import { actions as recsActions, recsSelectors } from "~/recs-events";
import { MAX_FETCH_FIELDS } from "~/recs-events/model";
import { FileImportAPI, RecAPI } from "@ai360/core";

import * as panelActions from "../../../../actions";
import { getFieldGuidFromDimIdx } from "../../../common/accordion/model";
import {
    createRecAccordionItems,
    getRecGuidFromDimIdx,
} from "../../../common/accordion/rec-event-accordion-item";
import * as commonSagas from "../../../common/rec-event-sagas";
import { StatusCodes } from "../../../common/status-messages";
import * as recInfoActions from "../rec-info/actions";
import { messages } from "../i18n-messages";
import * as selectors from "./selectors";
import * as actions from "./actions";

const _filterRecList = (recList, recFilter, selectedRecGuidSet, recSearch = "") => {
    if (recFilter) {
        const { cropGuid, recTypeGuid, onlySelected, seasonGuid } = recFilter;
        return recList.filter(
            (rec) =>
                (!onlySelected || selectedRecGuidSet.has(rec.recGeneralGuid)) &&
                (!recSearch.length || rec.recName?.toLowerCase().includes(recSearch)) &&
                (!seasonGuid || rec.croppingSeasonGuid === seasonGuid) &&
                (!recTypeGuid || rec.recTypeGuid === recTypeGuid) &&
                (!cropGuid || (rec.cropGuids && rec.cropGuids.includes(cropGuid)))
        );
    } else {
        return recList.filter((rec) => rec.recName?.toLowerCase().includes(recSearch));
    }
};

const filterRecOptions = function* () {
    const { filter, filterInfo } = yield select(selectors.getModuleState);
    const recTypeFilter = yield select(selectors.getRecFilter);
    const recFilter = filter;
    const { crops, croppingSeasons, recTypes, filters } = filterInfo;
    let cropOptions = [];
    const cropGuids = [];
    const uniqueCrops = [];
    let croppingSeasonOptions = [];
    const uniqueSeasons = [];
    const uniqueTypeOptions = [];
    let recTypeOptions = [];
    if (filters && Object.keys(filters).length > 0) {
        const chkFilter = (list, guid, cat, filter) =>
            filter == null ||
            filter.length === 0 ||
            (list.has(guid) && list.get(guid)[cat].has(filter));
        const rTs = filters.filter((rf) => {
            return (
                chkFilter(recTypes, rf.recTypeGuid, "croppingSeasonGuids", recFilter.seasonGuid) &&
                chkFilter(recTypes, rf.recTypeGuid, "cropGuids", recFilter.cropGuid)
            );
        });
        const uniqueRTs = new Map(rTs.map((rt) => [rt.recTypeGuid, `${rt.layerType}`]));
        uniqueTypeOptions.push(
            ...Array.from(uniqueRTs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        uniqueTypeOptions.sort((t1, t2) =>
            t1.label < t2.label ? -1 : t1.label === t2.label ? 0 : 1
        );
        // Get the current selected filter
        const recListMapWithFilter =
            recTypeFilter.activeTab === recsActions.RecEventListTabs.ACTIVE
                ? yield select(recsSelectors.getFieldGuidToRecListMap)
                : yield select(recsSelectors.getFieldGuidToInactiveRecListMap);
        const allRecsList = [...recListMapWithFilter.values()].flat();
        // Get the list of unique RecTypeNames
        recTypeOptions = uniqueTypeOptions.filter((t) =>
            allRecsList.some((r) => r.recTypeGuid === t.value)
        );
        const rcs = filters.filter((rf) => {
            return (
                chkFilter(
                    croppingSeasons,
                    rf.croppingSeasonGuid,
                    "recTypeGuids",
                    recFilter.recTypeGuid
                ) &&
                chkFilter(croppingSeasons, rf.croppingSeasonGuid, "cropGuids", recFilter.cropGuid)
            );
        });
        const uniqueCSs = new Map(rcs.map((cs) => [cs.croppingSeasonGuid, `${cs.croppingSeason}`]));
        uniqueSeasons.push(
            ...Array.from(uniqueCSs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        uniqueSeasons.sort((a, b) => a.label - b.label).reverse();
        croppingSeasonOptions = uniqueSeasons.filter((cs) =>
            allRecsList.some((e) => e.croppingSeasonGuid === cs.value)
        );

        const rc = filters.filter((c) => {
            return (
                chkFilter(crops, c.cropGuid, "croppingSeasonGuids", recFilter.seasonGuid) &&
                chkFilter(crops, c.cropGuid, "recTypeGuids", recFilter.recTypeGuid) &&
                c.crop &&
                c.cropGuid
            );
        });
        allRecsList.forEach((r) => r.cropGuids.forEach((cropGuid) => cropGuids.push(cropGuid)));
        const uniqueCs = new Map(rc.map((crop) => [crop.cropGuid, crop.crop]));
        uniqueCrops.push(
            ...Array.from(uniqueCs.entries()).map(([value, label]) => ({
                label,
                value,
            }))
        );
        uniqueCrops.sort((c1, c2) => (c1.label < c2.label ? -1 : c1.label === c2.label ? 0 : 1));
        cropOptions = uniqueCrops.filter((c) => cropGuids.includes(c.value));
    }
    yield put(
        actions.setRecFilterOptions({
            cropOptions,
            croppingSeasonOptions,
            recTypeOptions,
        })
    );
};

const addAccordionRecItems = function* () {
    const accordionId = yield select(selectors.getAccordionId);
    const expandedGuidSet = yield select(selectors.getExpandedFieldGuidSet);
    const fieldGuidToRecListMap = yield select(recsSelectors.getFieldGuidToRecListMap);
    const fieldGuidToInactiveRecListMap = yield select(
        recsSelectors.getFieldGuidToInactiveRecListMap
    );
    const fieldGuidToFieldMap = yield select(recsSelectors.getFieldGuidToFieldMap);
    const selectedRecGuidSet = yield select(selectors.getSelectedRecGuidSet);
    const searchValue = yield select(selectors.getSearchValue);
    let recFilter = yield select(selectors.getRecFilter);
    const { cropGuid, onlySelected, seasonGuid, recTypeGuid, activeTab } = recFilter;
    if (!onlySelected && !seasonGuid && !recTypeGuid && !cropGuid) {
        recFilter = null;
    }

    const recListMap =
        activeTab === recsActions.RecEventListTabs.INACTIVE
            ? fieldGuidToInactiveRecListMap
            : fieldGuidToRecListMap;

    yield* commonSagas.addAccordionRecEventItems(
        accordionId,
        createRecAccordionItems,
        expandedGuidSet,
        recFilter,
        searchValue,
        _filterRecList,
        recListMap,
        fieldGuidToFieldMap,
        selectedRecGuidSet
    );
};

const expandItemOnSave = function* () {
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);
    if (fieldGuidToRecDetails.size === 1) {
        const fieldGuid = fieldGuidToRecDetails.keys().next().value;
        yield put(actions.updateExpandedFieldGuidSet(fieldGuid, true));
    }
};

const messageSubscriptions = function* () {
    const handleExportFile = function* (message) {
        if (message.exportFileUrl && message.exportFileName) {
            const resp = yield call(fetch, message.exportFileUrl);
            if (resp.status === 200) {
                // Response is OK
                resp.blob().then((blob) => fileSaver.saveAs(blob, message.exportFileName));
                yield put(
                    notificationActions.pushToasterMessage(messages.exportComplete, MSGTYPE.INFO)
                );
            } else {
                // This isn't going to be able to update the status from this end, but it will give the user a hint to retry the export.
                // This will only come up if there's some kind of error fetching the file from the bucket.
                yield put(
                    notificationActions.pushToasterMessage(messages.exportFailed, MSGTYPE.ERROR)
                );
            }
        } else {
            yield put(notificationActions.pushToasterMessage(messages.exportFailed, MSGTYPE.ERROR));
        }
    };

    const handleCreatingControllerFile = function* (messageObject) {
        const message = `Creating prescription for Rec ${messageObject.recName}`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleCreatedClientInJdOpsCenter = function* (messageObject) {
        const message = `Created Client in JD Ops Center named ${messageObject.clientName}`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleFieldOverlapsWithExistingBoundary = function* () {
        const message = "Field overlaps with existing boundary in the JD Ops Center";
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleCreatedFieldInJdOpsCenter = function* (messageObject) {
        const message = `Created Field in JD Ops Center named ${messageObject.fieldName} on Farm ${messageObject.farmName}`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleRetrievedFieldFromJdOpsCenter = function* (messageObject) {
        const message = `Found matching Field > ${messageObject.fieldName} in JD Ops Center`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleCreatedProductInJdOpsCenter = function* (messageObject) {
        const message = `Created ${messageObject.productType} > ${messageObject.productName} in JD Ops Center`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleRetrievedProductFromJdOpsCenter = function* (messageObject) {
        const message = `Found matching Product ${messageObject.productType} > ${messageObject.productName} in JD Ops Center`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleSentWorkPlanToJdOpsCenter = function* (messageObject) {
        const prod = parseInt(messageObject.numberOfInputs) > 1 ? "Products" : "Product";
        const message = `Created Work Plan in JD Ops Center containing ${messageObject.numberOfInputs} ${prod}`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    const handleUpdatedWorkPlanInJdOpsCenter = function* (messageObject) {
        const prod = parseInt(messageObject.numberOfInputs) > 1 ? "Products" : "Product";
        const message = `Updated Work Plan in JD Ops Center containing ${messageObject.numberOfInputs} ${prod}`;
        yield put(notificationActions.sendToValidationFeedback(message));
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "exportFile",
            generator: handleExportFile,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "creatingControllerFile",
            generator: handleCreatingControllerFile,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "createdClientInJdOpsCenter",
            generator: handleCreatedClientInJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "fieldOverlapsWithExistingBoundary",
            generator: handleFieldOverlapsWithExistingBoundary,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "createdFieldInJdOpsCenter",
            generator: handleCreatedFieldInJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "retrievedFieldFromJdOpsCenter",
            generator: handleRetrievedFieldFromJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "createdProductInJdOpsCenter",
            generator: handleCreatedProductInJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "retrievedProductFromJdOpsCenter",
            generator: handleRetrievedProductFromJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "sentWorkPlanToJdOpsCenter",
            generator: handleSentWorkPlanToJdOpsCenter,
        })
    );

    yield put(
        messagingActions.subscribe(0, {
            eventName: "updatedWorkPlanInJdOpsCenter",
            generator: handleUpdatedWorkPlanInJdOpsCenter,
        })
    );
};

const onDeleteRecsFromAccordion = function* (action) {
    const { toDeleteDimIdxList } = action.payload;
    const accordionId = yield select(selectors.getAccordionId);
    const accordionItems = yield select(selectors.getAccordionItems);
    const fieldGuidToDeletedRecGuidsMap = new Map();

    const recGeneralGuidList = toDeleteDimIdxList.map((itemDimIdx) => {
        const fieldGuid = getFieldGuidFromDimIdx(accordionItems, [itemDimIdx[0]]);
        const recGeneralGuid = getRecGuidFromDimIdx(accordionItems, itemDimIdx);
        if (!fieldGuidToDeletedRecGuidsMap.has(fieldGuid)) {
            fieldGuidToDeletedRecGuidsMap.set(fieldGuid, new Set());
        }
        const fieldRecGuidSet = fieldGuidToDeletedRecGuidsMap.get(fieldGuid);
        fieldRecGuidSet.add(recGeneralGuid);
        return recGeneralGuid;
    });

    yield put(actions.deselectRecsFromDimIdxList(toDeleteDimIdxList));
    yield put(accordionActions.removeAccordionItemDimIdxList(accordionId, toDeleteDimIdxList));

    const deleteRecsAction = recsActions.deleteRecs(recGeneralGuidList);
    yield put(deleteRecsAction);

    let resultAction;
    do {
        resultAction = yield take([
            notificationActions.API_CALL_ERROR,
            recsActions.DELETE_RECS_SUCCESSFUL,
        ]);
    } while (
        resultAction.type === notificationActions.API_CALL_ERROR &&
        resultAction.payload.retryAction !== deleteRecsAction
    );

    if (resultAction.type === recsActions.DELETE_RECS_SUCCESSFUL) {
        const fieldGuidToRecListMap = yield select(recsSelectors.getFieldGuidToRecListMap);
        const countUpdatePayload = [];

        for (const [fieldGuid] of fieldGuidToDeletedRecGuidsMap.entries()) {
            const fieldRecList = fieldGuidToRecListMap.get(fieldGuid);
            countUpdatePayload.push({
                fieldGuid,
                recCount: fieldRecList.length,
            });
        }
        yield put(cdActions.batchUpdateFieldRecCount(countUpdatePayload));
        // Remove any associated visible layer from the store
        const visibleSurfaces = yield select(layerListSelectors.getVisibleSurfacesMap);
        for (const [fieldGuid, surface] of visibleSurfaces.entries()) {
            if (recGeneralGuidList.some((guid) => surface.recGeneralGuid === guid)) {
                yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
            }
        }
    }
};

const onExpandCollapse = function* (action) {
    const { accordionId, index } = action.payload;
    const recAccordionId = yield select(selectors.getAccordionId);
    const isLoading = yield select(selectors.getRecPanelLoading);
    if (!isLoading && accordionId === recAccordionId) {
        console.assert(index.length === 1);
        const items = yield select(selectors.getAccordionItems);
        const fieldGuid = getFieldGuidFromDimIdx(items, index);
        const isExpanded = action.type === accordionActions.EXPAND;
        yield put(actions.updateExpandedFieldGuidSet(fieldGuid, isExpanded));
    }
};

const onExpandCollapseAll = function* (action) {
    const { accordionId, dimensions } = action.payload;
    const recAccordionId = yield select(selectors.getAccordionId);
    const isLoading = yield select(selectors.getRecPanelLoading);
    if (!isLoading && accordionId === recAccordionId) {
        console.assert(dimensions.length === 1 && dimensions[0] === 1);
        const items = yield select(selectors.getAccordionItems);
        const isExpanded = action.type === accordionActions.EXPAND_ALL;
        const fieldGuids = items.map((item, idx) => getFieldGuidFromDimIdx(items, [idx]));
        yield put(actions.updateExpandedFieldGuidSet(fieldGuids, isExpanded));
    }
};

const onExportControllerFile = function* (action) {
    const { exportRecRequest } = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    try {
        yield call(FileImportAPI.exportControllerFiles, UserGuid, exportRecRequest);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    } finally {
        yield put(actions.clearExportRecGeneralGuidSet());
    }

    const fieldGuidToRecListMap = yield select(recsSelectors.getFieldGuidToRecListMap);
    const recToFieldMap = new Map();
    for (let [fieldGuid, recList] of fieldGuidToRecListMap.entries()) {
        if (recList && recList.length > 0) {
            for (let recGeneralGuid of exportRecRequest.recGeneralGuidList) {
                if (recList.some((rs) => rs.recGeneralGuid === recGeneralGuid)) {
                    recToFieldMap.set(recGeneralGuid, fieldGuid);
                }
            }
        }
    }
    for (let recGeneralGuid of exportRecRequest.recGeneralGuidList) {
        const fieldGuid = recToFieldMap.get(recGeneralGuid);
        yield put(
            recsActions.updateRecSummaryImportedStatus(
                fieldGuid,
                recGeneralGuid,
                StatusCodes.WaitingToProcess
            )
        );
    }
};

const onFilterChanged = function* () {
    let isPanelLoading = yield select(selectors.getRecPanelLoading);
    if (isPanelLoading) {
        do {
            const action = yield take(actions.SET_REC_PANEL_LOADING);
            isPanelLoading = action.payload.isPanelLoading;
        } while (isPanelLoading);
    }
    yield call(filterRecOptions);
    yield* addAccordionRecItems();
};

const onConfigureRecFilters = function* (action) {
    const { filters } = action.payload;
    if (!filters) {
        return;
    }
    const recSpecificFilters = filters.all.filter((i) => i.recTypeGuid);
    const uniqueCSs = new Map(
        recSpecificFilters.map((cs) => [`${cs.croppingSeason}`, cs.croppingSeasonGuid])
    );
    const croppingSeasons = new Map();
    for (const [croppingSeason, croppingSeasonGuid] of uniqueCSs.entries()) {
        const fieldGuids = recSpecificFilters
            .filter((rec) => rec.croppingSeasonGuid === croppingSeasonGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = recSpecificFilters.filter(
            (rec) => rec.croppingSeasonGuid === croppingSeasonGuid
        );
        croppingSeasons.set(croppingSeasonGuid, {
            croppingSeason,
            fieldGuids,
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
            recTypeGuids: new Set(filter.map(({ recTypeGuid }) => recTypeGuid).filter(Boolean)),
        });
    }

    const uniqueRTs = new Map(recSpecificFilters.map((rt) => [`${rt.layerType}`, rt.recTypeGuid]));
    const recTypes = new Map();
    for (const [layerType, recTypeGuid] of uniqueRTs.entries()) {
        const fieldGuids = recSpecificFilters
            .filter((rec) => rec.recTypeGuid === recTypeGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = recSpecificFilters.filter((rec) => rec.recTypeGuid === recTypeGuid);
        recTypes.set(recTypeGuid, {
            layerType,
            fieldGuids,
            cropGuids: new Set(filter.map(({ cropGuid }) => cropGuid).filter(Boolean)),
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
        });
    }

    const uniqueCs = new Map(
        recSpecificFilters
            .filter((rec) => rec.crop && rec.cropGuid)
            .map((crop) => [crop.crop, crop.cropGuid])
    );
    const crops = new Map();
    for (const [crop, cropGuid] of uniqueCs.entries()) {
        const fieldGuids = recSpecificFilters
            .filter((rec) => rec.cropGuid === cropGuid)
            .map(({ fieldGuid }) => fieldGuid);
        const filter = recSpecificFilters.filter((surface) => surface.cropGuid === cropGuid);
        crops.set(cropGuid, {
            crop,
            fieldGuids,
            croppingSeasonGuids: new Set(
                filter.map(({ croppingSeasonGuid }) => croppingSeasonGuid).filter(Boolean)
            ),
            recTypeGuids: new Set(filter.map(({ recTypeGuid }) => recTypeGuid).filter(Boolean)),
        });
    }

    yield put(
        actions.setRecFilterInfo({
            croppingSeasons,
            crops,
            recTypes,
            filters: recSpecificFilters,
        })
    );
    yield call(filterRecOptions);
};

const onSendJohnDeereWorkPlan = function* (action) {
    const { recGeneralGuid, workPlanRequest } = action.payload;

    try {
        const userGuid = yield select(getTheUserGuid);
        yield call(RecAPI.sendJohnDeereWorkPlan, workPlanRequest, recGeneralGuid, userGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        yield put(notificationActions.sendToValidationFeedback(err.apiResultObj.errors[0].message));
    } finally {
        //yield put(actions.setIsFieldLoading(false));
    }
};

const onRecsFetched = function* (action) {
    const { fieldGuidToRecListMap } = action.payload;
    const { fieldGuidToRecDetails } = yield select(recsSelectors.getModuleState);

    for (let [fieldGuid, recDetails] of fieldGuidToRecDetails.entries()) {
        const hasRecsToUpdate =
            fieldGuidToRecListMap.has(fieldGuid) &&
            fieldGuidToRecListMap.get(fieldGuid).length !== 0;

        if (hasRecsToUpdate) {
            const newRecList = fieldGuidToRecListMap.get(fieldGuid);
            const newRecSummary = newRecList.find((rec) => {
                const recDate = recDetails.momentCreatedDate.format("MM/DD/YYYY");
                const orRecDate = recDetails.momentCreatedDate.format("M/D/YYYY");
                const isRunning = recDetails.recAreaList.some((recArea) =>
                    recArea.recs.some((rec) => rec.isAnyEquationRun)
                );

                return (
                    recDetails.recGeneralGuid === rec.recGeneralGuid ||
                    (!recDetails.recGeneralGuid &&
                        isRunning &&
                        (recDate === rec.recDate || orRecDate === rec.recDate) &&
                        recDetails.name === rec.recName)
                );
            });

            if (newRecSummary) {
                yield put(recInfoActions.setRecSummary(newRecSummary));
            }
        }
    }

    yield* addAccordionRecItems();
    yield put(actions.setRecPanelLoading(false));
    yield call(filterRecOptions);
};

const reloadRecsOnSave = function* () {
    yield put(actions.setRecPanelLoading(true));
    yield put(recsActions.fetchSelectedFieldRecs());
    yield take(recsActions.fetchSelectedFieldRecsSucceeded);
    yield put(recsActions.refreshFilterOptions());
};

const removeDeselectedIfOnlySelected = function* (action) {
    const { onlySelected } = yield select(selectors.getRecFilter);
    if (!onlySelected) {
        return;
    }
    const { startDimIdx, endDimIdx } = action.payload;
    const accordionId = yield select(selectors.getAccordionId);
    const accordionItems = yield select(selectors.getAccordionItems);
    const itemsToRemove = commonSagas.removeDeselectedIfOnlySelected(
        accordionItems,
        endDimIdx,
        startDimIdx
    );
    if (itemsToRemove.length > 0) {
        yield put(accordionActions.removeAccordionItemDimIdxList(accordionId, itemsToRemove));
    }
};

const setupAccordionOnPanelActivated = function* () {
    do {
        const selectedFieldCount = yield select(cdSelectors.getSelectedFieldsCount);
        const waitForActions = [cdActions.ADD_SELECTED_FIELDS];
        if (selectedFieldCount > MAX_FETCH_FIELDS) {
            waitForActions.push(cdActions.CLEAR_SELECTED_FIELDS);
        }
        yield take(waitForActions);
        let activeModule;
        do {
            activeModule = (yield take(panelActions.ACTIONPANEL_SET_ACTIVEMODULE)).payload;
        } while (activeModule !== panelActions.ActionPanelModuleList.REC);

        yield put(actions.setRecPanelLoading(true));
        yield put(recsActions.fetchSelectedFieldRecs());
    } while (true);
};

const onClearFields = function* () {
    yield* updateSelectedRecs();
    yield* updateSelectedFieldRecs();
};

const updateSelectedRecs = function* () {
    const { fieldGuidToSelectedRecGuidSetMap } = yield select(selectors.getModuleState);
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const fieldGuidsToRemove = [...fieldGuidToSelectedRecGuidSetMap.keys()].filter(
        (fieldGuid) => !selectedFieldGuidSet.has(fieldGuid)
    );
    if (fieldGuidsToRemove.length > 0) {
        yield put(actions.deselectRecsForFieldGuids(fieldGuidsToRemove));
    }
    yield call(addAccordionRecItems);
};

const updateSelectedFieldRecs = function* () {
    const selectedFieldGuidSet = yield select(cdSelectors.getSelectedFieldGuids);
    const recListMap = yield select(recsSelectors.getFieldGuidToRecListMap);
    const recListInactiveMap = yield select(recsSelectors.getFieldGuidToInactiveRecListMap);
    const fieldGuidsToInclude = [...recListMap.keys()].filter((fieldGuid) =>
        selectedFieldGuidSet.has(fieldGuid)
    );
    const fieldGuidToParsedObjListMap = OrderedMap().withMutations((map) => {
        for (const fieldGuid of fieldGuidsToInclude) {
            map.set(fieldGuid, recListMap.get(fieldGuid) || []);
        }
    });
    const fieldGuidToParsedObjInactiveListMap = OrderedMap().withMutations((map) => {
        for (const fieldGuid of fieldGuidsToInclude) {
            map.set(fieldGuid, recListInactiveMap.get(fieldGuid) || []);
        }
    });

    yield put(
        recsActions.updateSelectedFieldRecs(
            fieldGuidToParsedObjListMap,
            fieldGuidToParsedObjInactiveListMap
        )
    );
};

const onActivateRecFromAccordion = function* (action) {
    const { toActivateDimIdx } = action.payload;
    const accordionItems = yield select(selectors.getAccordionItems);

    yield put(actions.setIsRecLoading(true));

    const fieldGuid = getFieldGuidFromDimIdx(accordionItems, [toActivateDimIdx[0]]);
    const agRecGeneralGuid = getRecGuidFromDimIdx(accordionItems, toActivateDimIdx);

    const activateRecAction = recsActions.activateRec(agRecGeneralGuid);
    yield put(activateRecAction);

    let resultAction;
    do {
        resultAction = yield take([
            notificationActions.API_CALL_ERROR,
            recsActions.ACTIVATE_REC_SUCCESSFUL,
        ]);
    } while (
        resultAction.type === notificationActions.API_CALL_ERROR &&
        resultAction.payload.retryAction !== activateRecAction
    );

    if (resultAction.type === recsActions.ACTIVATE_REC_SUCCESSFUL) {
        const fieldGuidToRecListMap = yield select(recsSelectors.getFieldGuidToRecListMap);
        const fieldRecList = fieldGuidToRecListMap.get(fieldGuid);
        yield put(cdActions.updateFieldRecCount(fieldGuid, fieldRecList.length));
    }

    yield put(actions.setIsRecLoading(false));
};

export const recListSaga = function* () {
    yield all([
        messageSubscriptions(),
        setupAccordionOnPanelActivated(),
        takeLatest(
            [
                recsActions.REMOVE_REC_GENERAL,
                recsActions.REPLACE_REC_GENERAL,
                recsActions.REMOVE_INACTIVE_REC_GENERAL,
                recsActions.REPLACE_INACTIVE_REC_GENERAL,
            ],
            addAccordionRecItems
        ),
        takeEvery([accordionActions.EXPAND, accordionActions.COLLAPSE], onExpandCollapse),
        takeEvery(
            [accordionActions.EXPAND_ALL, accordionActions.COLLAPSE_ALL],
            onExpandCollapseAll
        ),
        takeEvery(actions.DESELECT_RECS_FROM_ACCORDION, removeDeselectedIfOnlySelected),
        takeEvery(actions.DELETE_RECS_FROM_DIMIDX_LIST, onDeleteRecsFromAccordion),
        takeEvery(actions.EXPORT_CONTROLLER_FILE, onExportControllerFile),
        takeLatest(actions.SEND_JOHN_DEERE_WORK_PLAN, onSendJohnDeereWorkPlan),
        takeLatest([actions.SET_REC_FILTER, actions.CLEAR_REC_FILTER], onFilterChanged),
        takeLatest(actions.CONFIGURE_REC_FILTERS, onConfigureRecFilters),
        takeLatest(recInfoActions.CLOSE_REC_INFO, reloadRecsOnSave),
        takeEvery(recInfoActions.SAVE_REC_INFO, expandItemOnSave),
        takeLatest(recsActions.FETCH_RECS_SUCCEEDED, onRecsFetched),
        takeEvery(
            [cdActions.CLEAR_ALL_SELECTED_FIELDS, cdActions.CLEAR_SELECTED_FIELDS],
            onClearFields
        ),
        takeEvery(actions.ACTIVATE_REC_FROM_DIMIDX, onActivateRecFromAccordion),
        takeLatest(actions.UPDATE_SEARCH, onFilterChanged),
    ]);
};
