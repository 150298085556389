import { toast } from "react-toastify";
import { action } from "typesafe-actions";

export const API_CALL_ERROR = "notifications/API_CALL_ERROR";
export const CLEAR_TOASTER_MESSAGES = "notifications/CLEAR_TOASTER_MESSAGES";
export const PUSH_TOASTER_MSG = "notifications/PUSH_TOASTER_MSG";
export const UPDATE_NOTIFICATION_LIST = "notifications/UPDATE_NOTIFICATION_LIST";
export const UPDATE_NOTIFICATIONS_READ = "notifications/UPDATE_NOTIFICATIONS_READ";
export const FETCH_USER_NOTIFICATIONS = "notifications/FETCH_USER_NOTIFICATIONS";
export const OPEN_SAMPLING_LAYER = "notifications/OPEN_SAMPLING_LAYER";
export const SET_IS_LOADING = "notifications/SET_IS_LOADING";
export const SET_SAMPLING_ID = "notifications/SET_SAMPLING_ID";
export const SEND_TO_VALIDATION_FEEDBACK = "notifications/SEND_TO_VALIDATION_FEEDBACK";

export const MSGTYPE = toast.TYPE;

export const apiCallError = (
    err: string[],
    retryAction?: unknown,
    intlMessageObj?: string,
    intlMessageValues?: Record<string, any>
): any => ({
    type: API_CALL_ERROR,
    payload: { err, retryAction, intlMessageObj, intlMessageValues },
});

export const clearToasterMessages = () => action(CLEAR_TOASTER_MESSAGES);

export const pushToasterMessage = (
    message: string,
    type?: string,
    values?: Record<string, any>,
    autoClose: number | boolean = true
) => action(PUSH_TOASTER_MSG, { autoClose, message, type, values });

export const fetchUserNotifications = () => ({
    type: FETCH_USER_NOTIFICATIONS,
});

export const openSamplingLayer = (fieldGuid, agEventGeneralGuid) => ({
    type: OPEN_SAMPLING_LAYER,
    payload: { fieldGuid, agEventGeneralGuid },
});

export const updateNotificationList = (notificationList) => ({
    type: UPDATE_NOTIFICATION_LIST,
    payload: { notificationList },
});

export const updateNotificationsRead = () => ({
    type: UPDATE_NOTIFICATIONS_READ,
});

export const setSamplingId = (samplingId) => ({
    type: SET_SAMPLING_ID,
    payload: { samplingId },
});

export const sendToValidationFeedback = (message) => ({
    type: SEND_TO_VALIDATION_FEEDBACK,
    payload: { message },
});

export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    payload: { isLoading },
});
