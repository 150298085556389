import { adminData } from "~/admin/data";
// Model
export const ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_ACTIVE_ONLY = "activeOnly";
export const PROPS_ADDRESS_TYPE_LIST = "addressTypeList";
export const PROPS_AGVANCE_DATABASE_ID = "agvanceDatabaseId";
export const PROPS_AGVANCE_USER_ID = "agvanceUserId";
export const PROPS_AGVANCE_USER_PASSWORD = "agvanceUserPassword";
export const PROPS_CAN_DELETE = "canDelete";
export const PROPS_CAN_MODIFY = "canModify";
export const PROPS_CITY = "city";
export const PROPS_COST_CENTER_NUMBER = "costCenterNumber";
export const PROPS_COUNTRIES_LIST = "countriesList";
export const PROPS_ERROR_MESSAGE = "errorMessage";
export const PROPS_HAS_CHILDREN = "hasChildren";
export const PROPS_LOCATION_ID = "locationId";
export const PROPS_NOTES = "notes";
export const PROPS_ORG_LEVEL_ACTIVE_YN = "orgLevelActiveYn";
export const PROPS_ORG_LEVEL_ADDRESS = "orgLevelAddress";
export const PROPS_ORG_LEVEL_ADDRESS_LIST = "orgLevelAddressList";
export const PROPS_ORG_LEVEL_EQUIPMENT_LIST = "orgLevelEquipmentList";
export const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
export const PROPS_ORG_LEVEL_ID = "id";
export const PROPS_ORG_LEVEL_NAME = "orgLevelName";
export const PROPS_ORG_LEVEL_NOTES = "orgLevelNotes";
export const PROPS_ORG_LEVEL_PERSON_LIST = "personOrgLevelList";
export const PROPS_ORG_LEVEL_PHONE_NUMBER_LIST = "orgLevelPhoneNumberList";
export const PROPS_ORG_LEVEL_UI_LABEL_LIST = "orgLevelUiLabelList";
export const PROPS_ORG_LEVEL_URL_LIST = "orgLevelUrlList";
export const PROPS_ORG_TYPE_GUID = "orgTypeGuid";
export const PROPS_ORG_TYPE_LEVEL = "orgTypeLevel";
export const PROPS_ORG_TYPE_NAME = "orgTypeName";
export const PROPS_PARENT_ORG_LEVEL_GUID = "parentOrgLevelGuid";
export const PROPS_PARTNER_ORG_ID = "partnerOrganizationId";
export const PROPS_PERSON_AFFILIATION_LIST = "personAffiliationList";
export const PROPS_PERSON_ORG_LEVEL_LIST = "personOrgLevelList";
export const PROPS_PHONE_NUMBER_LIST = "orgLevelPhoneNumberList";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;
export const PROPS_STATE_LIST = "stateList";
export const PROPS_URL_LIST = "orgLevelUrlList";
export const PROPS_REPORT_LOGO = "reportLogo";
export const PROPS_CAN_EDIT_REPORT_LOGO = "canEditReportLogo";
export const PROPS_UPDATED_AGVANCE_ORG_LEVEL_GUID = "updatedAgvanceOrgLevelGuid";
export const PROPS_AGVANCE_LOCATION_GUID = "agvanceLocationGuid";
export const PROPS_AGVANCE_LOCATION_ID = "agvanceLocationId";
export const PROP_REQUIRE_CUSTOMER_SEARCH_FLAG = "requireCustomerSearch";
export const PROPS_IS_AGVANCE_CUSTOMER_EXISTS = "isAgvanceCustomerExists";
