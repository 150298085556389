import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { withUser } from "~/hocs";
import { v4 as uuid } from "uuid";

import { Menu } from "~/core";
import { messages as globalMessages } from "~/i18n-messages";
import { recsSelectors, actions as recsEventsActions } from "~/recs-events";
import { StatusCodes } from "../../../../../common/status-messages";
import { DISABLE_EXPORT_STATUSES, DISALLOW_REC_CONVERT_COPY_STATUSES } from "../../selectors";

import { DeleteConfDialog } from "../../../../../context-menus/item-delete-confirmation-dialog";

import { messages } from "../../../i18n-messages";
import * as actions from "../../actions";
import * as recListActions from "../../../rec-list/actions";
import * as recInfoActions from "../../../rec-info/actions";
import { models } from "~/recs-events";
import { ErrorDetailsContextMenuLabel } from "~/action-panel/components/common/error-details-dialog/error-details-dialog";
//import { OrderedMap } from "immutable";
import SendRecValidation from "../../../rec-send/send-rec-validation-feedback";

class ContextMenu_ extends Component {
    static propTypes = {
        activateRec: PropTypes.any,
        activeTab: PropTypes.any,
        convertRecsToEvents: PropTypes.any,
        copyRecInfo: PropTypes.func.isRequired,
        deleteRec: PropTypes.func.isRequired,
        intl: intlShape.isRequired,
        itemDimIdx: PropTypes.array.isRequired,
        objGuid: PropTypes.string.isRequired,
        openExportControllerFileModal: PropTypes.func.isRequired,
        recSummary: PropTypes.object.isRequired,
        showRecInfo: PropTypes.func.isRequired,
        showErrorDetails: PropTypes.func.isRequired,
        userRole: PropTypes.object,
        recGeneralGuidToRecMap: PropTypes.instanceOf(Map),
        recIntegrationPartnerAcccess: PropTypes.instanceOf(Array), //OrderedMap),
        onSendJohnDeereWorkPlan: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isContextMenuExpanded: false,
            isDeleteConfirmationOpen: false,
            isExportControllerModalOpen: false,
            showWorkPlanValidationModal: false,
        };
    }

    _getMenuItems() {
        const {
            convertRecsToEvents,
            copyRecInfo,
            openExportControllerFileModal,
            showRecInfo,
            showErrorDetails,
            recGeneralGuidToRecMap,
            recSummary,
            objGuid,
            userRole,
            activateRec,
            activeTab,
            recIntegrationPartnerAcccess,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const menuItems = [];

        if (
            activeTab === recsEventsActions.RecEventListTabs.ACTIVE ||
            activeTab === recsEventsActions.RecEventListTabs.SELECTED
        ) {
            menuItems.push({
                key: 0,
                label: formatMessage(messages.viewEditDetails),
                action: showRecInfo,
            });
            menuItems.push({
                key: 2,
                label: formatMessage(messages.delete),
                action: () => this.setState({ isDeleteConfirmationOpen: true }),
            });

            // Removes Copy from the menu for Equation Rec as well as for Recs with disallowed statuses
            if (
                recGeneralGuidToRecMap.has(objGuid) &&
                !(
                    recGeneralGuidToRecMap.get(objGuid).recType ===
                        models.REC_TYPE_NAME_EQUATION_APPLICATION ||
                    recGeneralGuidToRecMap.get(objGuid).recType ===
                        models.REC_TYPE_NAME_EQUATION_PLANTING
                ) &&
                !DISALLOW_REC_CONVERT_COPY_STATUSES.includes(
                    recGeneralGuidToRecMap.get(objGuid).recStatuses
                )
            ) {
                menuItems.splice(1, 0, {
                    key: 1,
                    label: formatMessage(messages.copy),
                    action: copyRecInfo,
                });
            }

            if (
                this._isApplicationRec() &&
                !DISALLOW_REC_CONVERT_COPY_STATUSES.includes(
                    recGeneralGuidToRecMap.get(objGuid).recStatuses
                )
            ) {
                menuItems.push({
                    key: 3,
                    label: formatMessage(messages.convertRecToEvent),
                    action: convertRecsToEvents,
                });
            }

            if (
                this._isApplicationRec() &&
                userRole.sendRecTo &&
                this._userHasJdOpsCenterAccess()
            ) {
                const allSubMenuItems = [
                    {
                        key: 0,
                        label: formatMessage(messages.jdOperationsCenterText),
                        action: () => this.setState({ showWorkPlanValidationModal: true }), //this.onSendRecTo(),
                    },
                ];
                const userAccessibleRecIntegrationPartners = recIntegrationPartnerAcccess.map(
                    ({ name }) => name
                );
                const userAccessibleSubmenuItems = allSubMenuItems.filter(({ label }) =>
                    userAccessibleRecIntegrationPartners.includes(label)
                );

                menuItems.push({
                    key: 4,
                    label: formatMessage(messages.sendRecToText),
                    subMenuItems: userAccessibleSubmenuItems,
                });
            }

            if (userRole.exportRecs) {
                menuItems.push({
                    key: 5,
                    label: formatMessage(messages.exportControllerFile, {
                        count: 1,
                    }),
                    action: openExportControllerFileModal,
                    disabled: DISABLE_EXPORT_STATUSES.includes(
                        recGeneralGuidToRecMap.get(objGuid).recStatuses
                    ),
                });
            }
        } else {
            if (userRole.activeInactive) {
                menuItems.push({
                    key: menuItems.length + 1,
                    label: formatMessage(messages.activateRec),
                    action: () => activateRec(),
                });
            }
        }
        if (
            [
                StatusCodes.ErrorProcessingSurface,
                StatusCodes.ErrorCreatingRec,
                StatusCodes.ExportFailed,
            ].includes(recSummary.importedStatus)
        ) {
            menuItems.push({
                key: menuItems.length + 1,
                label: formatMessage(ErrorDetailsContextMenuLabel),
                action: () => showErrorDetails(recSummary),
            });
        }

        return menuItems;
    }

    _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }

    onSendRecTo(evt) {
        const { recSummary } = this.props;
        console.warn(recSummary);
        /*
            TODO:
                1. Update to handle multiple recs selected - may have to move this function UP to a higher component
                to be able to retrieve from the tab context menu verses this Rec strip context menu.
                2. Obviously lots of hard-coded properties
                3.   - the shapefile location will come from Max's new endpoint.
                4.   - And the johnDeereOrganizationId will come from the org-level tracking of JD org IDs
                       that still needs to be implemented.
        */
        const workPlanRequest = {
            fieldGuid: recSummary.fieldGuid,
            queueJobGuid: uuid(),
            workOrder: evt.workOrder,
            instructions: evt.instructions,
        };

        this.props.onSendJohnDeereWorkPlan(recSummary.recGeneralGuid, workPlanRequest);
    }

    _isApplicationRec() {
        const { recGeneralGuidToRecMap, objGuid } = this.props;
        return (
            recGeneralGuidToRecMap.has(objGuid) &&
            (recGeneralGuidToRecMap.get(objGuid).recType ===
                models.REC_TYPE_NAME_MANUAL_APPLICATION ||
                recGeneralGuidToRecMap.get(objGuid).recType ===
                    models.REC_TYPE_NAME_EQUATION_APPLICATION)
        );
    }

    _userHasJdOpsCenterAccess() {
        return this.props.recIntegrationPartnerAcccess.some(
            (x) => x.name === "JD Operations Center"
        );
    }

    render() {
        const { formatMessage } = this.props.intl;
        const { itemDimIdx, objGuid, deleteRec } = this.props;
        const { isDeleteConfirmationOpen, showWorkPlanValidationModal } = this.state;

        const onDelete = () => deleteRec(itemDimIdx, objGuid);
        const deleteConfDialog = !isDeleteConfirmationOpen
            ? null
            : DeleteConfDialog({
                  formatMessage,
                  deleteConfirmationTitle: globalMessages.confirmTitle,
                  deleteConfirmationMessage: messages.deleteRecConfirmation,
                  onConfirmationClick: (confirmation) => {
                      const afterClose = !confirmation ? () => null : onDelete;
                      this.setState({ isDeleteConfirmationOpen: false }, afterClose);
                  },
              });

        const contextMenuClassNames = classNames("context-menu", {
            expanded: this.state.isContextMenuExpanded,
        });
        return (
            <div className="context-menu-container" onClick={(event) => this._onClick(event)}>
                <Menu
                    className={contextMenuClassNames}
                    isDotMenu={true}
                    getMenuItems={() => this._getMenuItems()}
                    onExpand={() => this.setState({ isContextMenuExpanded: true })}
                    onCollapse={() => this.setState({ isContextMenuExpanded: false })}
                />
                {deleteConfDialog}
                {showWorkPlanValidationModal && (
                    <SendRecValidation
                        isOpen={showWorkPlanValidationModal}
                        onSend={(evt) => this.onSendRecTo(evt)}
                        onClose={() => this.setState({ showWorkPlanValidationModal: false })}
                        sendDestination={"JD Operations Center"}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { activeTab, objGuid } = ownProps;
    const isInactiveTab = activeTab === recsEventsActions.RecEventListTabs.INACTIVE;
    const recGeneralGuidToRecMap = isInactiveTab
        ? recsSelectors.getRecGeneralGuidToInactiveRecSummaryMap(state)
        : recsSelectors.getRecGeneralGuidToRecSummaryMap(state);
    const recSummary = recGeneralGuidToRecMap.get(objGuid);
    const recIntegrationPartnerAcccess = recsSelectors.getRecIntegrationPartnerAccess(state);
    return { recGeneralGuidToRecMap, recSummary, recIntegrationPartnerAcccess };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { itemDimIdx } = ownProps;
    return {
        copyRecInfo: (recSummary) => dispatch(recInfoActions.copyRecInfo(recSummary)),
        convertRecsToEvents: (recGeneralGuidToFieldGuidMap) =>
            dispatch(recListActions.setConvertRecsToEventsGuidMap(recGeneralGuidToFieldGuidMap)),
        deleteRec: () => dispatch(recListActions.deleteRecsFromDimIdxList([itemDimIdx])),
        openExportControllerFileModal: (recGeneralGuids) =>
            dispatch(recListActions.setExportRecGeneralGuidSet(recGeneralGuids)),
        showRecInfo: (recSummary) => dispatch(recInfoActions.showRecInfo(recSummary)),
        showErrorDetails: (recSummary) => dispatch(actions.showErrorDetails(recSummary)),
        activateRec: () => dispatch(actions.activateRecFromDimIdx(itemDimIdx)),
        onSendJohnDeereWorkPlan: (recGeneralGuid, workPlan) =>
            dispatch(actions.sendJohnDeereWorkPlan(recGeneralGuid, workPlan)),
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { recSummary } = stateProps;
    return {
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        copyRecInfo: () => dispatchProps.copyRecInfo(recSummary),
        convertRecsToEvents: () =>
            dispatchProps.convertRecsToEvents(
                new Map([[recSummary.recGeneralGuid, recSummary.fieldGuid]])
            ),
        openExportControllerFileModal: () =>
            dispatchProps.openExportControllerFileModal([recSummary.recGeneralGuid]),
        showRecInfo: () => dispatchProps.showRecInfo(recSummary),
    };
};

export const ContextMenu = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(withUser(ContextMenu_)));
