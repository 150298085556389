import { defineMessages } from "react-intl";

export const messages = defineMessages({
    activateCustomerText: {
        id: "customer.activateCustomerText",
        defaultMessage: "Activate Customer",
    },
    activateFieldText: {
        id: "field.activateFieldText",
        defaultMessage: "Activate Field",
    },
    addNewFieldText: {
        id: "customer.addNewFieldText",
        defaultMessage: "Add New Field",
    },
    addNonFieldFeatureText: {
        id: "customer.addNonFieldFeatureText",
        defaultMessage: "Add Non-Field Feature",
    },
    addSample: {
        id: "layers.addSample",
        defaultMessage: "Add Sample",
    },
    applicationEquationText: {
        id: "recs.applicationEquationText",
        defaultMessage: "Application (Equation)",
    },
    applicationManualText: {
        id: "recs.applicationManualText",
        defaultMessage: "Application (Manual)",
    },
    batchEditRecsText: {
        id: "recs.batchEditRecsText",
        defaultMessage: "Batch Edit Recs",
    },
    batchCopy: { id: "events.batchCopy", defaultMessage: "Batch Copy" },
    batchExportPoints: {
        id: "events.batchExportPoints",
        defaultMessage: "Batch Export Points",
    },
    batchExportEventData: {
        id: "events.batchExportEventData",
        defaultMessage: "Batch Export Data",
    },
    batchExportSurface: {
        id: "events.batchExportSurface",
        defaultMessage: "Batch Export Surface",
    },
    batchSendRecsToText: {
        id: "recs.batchSendRecsToText",
        defaultMessage: "Send Recs To",
    },
    calibrateYield: {
        id: "events.calibrateYield",
        defaultMessage: "Yield Calibration",
    },
    clearSelected: {
        id: "actionPanel.contextMenu.clearSelected",
        defaultMessage: "Clear Selected",
    },
    clearSelectedFieldsText: {
        id: "customer.clearSelectedFieldsText",
        defaultMessage: "Clear Selected Fields",
    },
    collapseAll: {
        id: "actionPanel.contextMenu.collapseAll",
        defaultMessage: "Collapse All",
    },
    convertRecsToEvents: {
        id: "recs.convertRecsToEvents",
        defaultMessage: "Convert To Events",
    },
    deleteCustomerConfirmation: {
        id: "field.confirmationDialog.deleteCustomerConfirmation",
        defaultMessage:
            "Deleting this Customer will also remove all associated fields and data. Do you want to delete?",
    },
    deleteCustomerConfirmation2: {
        id: "field.confirmationDialog.deleteCustomerConfirmation2",
        defaultMessage: "Are you sure you want to delete this Customer?",
    },
    deleteCustomerText: {
        id: "customer.deleteCustomerText",
        defaultMessage: "Delete Customer",
    },
    deleteFieldConfirmation: {
        id: "field.confirmationDialog.deleteFieldConfirmation",
        defaultMessage:
            "Deleting this field will remove all of its associated data. Are you sure you want to delete this field?",
    },
    deleteFieldConfirmationIgnoreAgvance: {
        id: "field.confirmationDialog.deleteFieldConfirmationIgnoreAgvance",
        defaultMessage:
            "This Field is in an invalid state and no longer connected to Agvance. Are you sure want to delete this Field?",
    },
    deleteFieldText: {
        id: "field.deleteFieldText",
        defaultMessage: "Delete Field",
    },
    deleteSample: {
        id: "layers.deleteSample",
        defaultMessage: "Delete Sample",
    },
    deleteSampleConfirmation: {
        id: "layers.deleteSampleConfirmation",
        defaultMessage: "Are you sure that you want to delete this sample?",
    },
    deleteSelected: {
        id: "actionPanel.contextMenu.deleteSelected",
        defaultMessage: "Delete Selected",
    },
    editSample: {
        id: "layers.editSample",
        defaultMessage: "Edit Sample",
    },
    eventSelectionText: {
        id: "actionPanel.contextMenu.eventSelectionText",
        defaultMessage: "Event Selection",
    },
    expandAll: {
        id: "actionPanel.contextMenu.expandAll",
        defaultMessage: "Expand All",
    },
    exportBoundaryText: {
        id: "field.exportBoundaryText",
        defaultMessage: "Export Boundary",
    },
    exportDetailText: {
        id: "field.exportDetailText",
        defaultMessage: "Export Field Detail",
    },
    fourMationText: {
        id: "analysisLayers.fourMationText",
        defaultMessage: "4Mation",
    },
    harvestText: { id: "events.harvestText", defaultMessage: "Harvest" },
    imagerySetupText: {
        id: "analysisLayers.imagerySetupText",
        defaultMessage: "Imagery Setup",
    },
    irrigationText: {
        id: "events.irrigationText",
        defaultMessage: "Irrigation",
    },
    managementArea: {
        id: "analysisLayers.managementAreaText",
        defaultMessage: "Management Area",
    },
    mergeEventsText: {
        id: "events.mergeEventsText",
        defaultMessage: "Merge Events",
    },
    combineFieldsText: {
        id: "field.combineFieldsText",
        defaultMessage: "Combine Fields",
    },
    moveFieldsText: {
        id: "customer.moveFieldsText",
        defaultMessage: "Move {count, plural, one {Field} other {Fields}}",
    },
    newAnalysisLayerText: {
        id: "analysisLayers.newAnalysisLayerText",
        defaultMessage: "New Analysis Layer",
    },
    newBatchAnalysis: {
        id: "analysisLayers.newBatchAnalysis",
        defaultMessage: "New Batch Analysis",
    },
    newBatchEventText: {
        id: "events.newBatchEventText",
        defaultMessage: "New Batch Event",
    },
    newBatchRecText: {
        id: "recs.newBatchRecText",
        defaultMessage: "New Batch Rec",
    },
    newEventText: { id: "events.newEventText", defaultMessage: "New Event" },
    newRecText: { id: "recs.newRecText", defaultMessage: "New Rec" },
    multiFieldEcDataText: {
        id: "analysisLayers.multiFieldEcDataText",
        defaultMessage: "Multi-Field EC Data",
    },
    normalizedYieldText: {
        id: "analysisLayers.normalizedYieldText",
        defaultMessage: "Normalized Yield",
    },
    profitLossText: {
        id: "analysisLayers.profitLossText",
        defaultMessage: "Profit/Loss",
    },
    plantingManualText: {
        id: "recs.plantingManualText",
        defaultMessage: "Planting (Manual)",
    },
    plantingText: { id: "events.plantingText", defaultMessage: "Planting" },
    recSelectionText: {
        id: "actionPanel.contextMenu.recSelectionText",
        defaultMessage: "Rec Selection",
    },
    reprocessSoilTypes: {
        id: "actionPanel.contextMenu.reprocessSoilTypes",
        defaultMessage: "Reprocess Soil Types",
    },
    samplingSoilText: {
        id: "events.samplingSoilText",
        defaultMessage: "Sampling - Soil",
    },
    seedStrongText: {
        id: "analysisLayers.seedStrongText",
        defaultMessage: "SeedStrong",
    },
    seedStrongDHText: {
        id: "analysisLayers.seedStrongDHText",
        defaultMessage: "SeedStrong DH",
    },
    selectAll: {
        id: "actionPanel.contextMenu.selectAll",
        defaultMessage: "Select All",
    },
    selectAllFieldsText: {
        id: "customer.selectAllFieldsText",
        defaultMessage: "Select All Fields",
    },
    splitScreenCompare: {
        id: "actionPanel.contextMenu.splitScreenCompare",
        defaultMessage: "Split Screen Compare",
    },
    tillageText: { id: "events.tillageText", defaultMessage: "Tillage" },
    viewEditCustomerText: {
        id: "customer.viewEditCustomer",
        defaultMessage: "View/Edit Customer",
    },
    viewEditFieldText: {
        id: "field.viewEditFieldText",
        defaultMessage: "View / Edit Field",
    },
    viewResults: {
        id: "layers.viewResults",
        defaultMessage: "View Results",
    },
    zoomToFarmText: { id: "field.zoomToFarmText", defaultMessage: "Farm" },
    zoomToFieldText: { id: "field.zoomToFieldText", defaultMessage: "Field" },
    zoomToFieldsText: {
        id: "customer.zoomToFieldsText",
        defaultMessage: "Zoom To Fields",
    },
    zoomToText: { id: "field.zoomToText", defaultMessage: "Zoom To" },
    editNonFieldFeature: {
        id: "nonFieldFeatureDataContextMenu.editFeature",
        defaultMessage: "Edit Feature",
    },
    deleteNonFieldFeature: {
        id: "nonFieldFeatureDataContextMenu.deleteFeature",
        defaultMessage: "Delete Feature",
    },
    deleteNonFieldFeatureConfirmation: {
        id: "nonFieldFeatureDataContextMenu.deleteFeatureConfirmation",
        defaultMessage: "Are you sure that you want to delete this non field feature?",
    },
    required: {
        id: "analysisLayers.required",
        defaultMessage: "Required",
    },
    selectEventError: {
        id: "analysisLayers.selectEventError",
        defaultMessage: "Please select at least 1 imported Harvest Event.",
    },
});
