import { apiUrl } from "@ai360/core";

export const ANALYSIS_LAYERS_CATEGORY = "Analysis Layers";
export const ANALYTICS_CATEGORY = "Analytics";
export const DEFAULT_RADIO_VALUE = 0;
export const EQUATION_APPLICATION_CATEGORY = "Equations - Application";
export const EQUATION_PLANTING_CATEGORY = "Equations - Planting";
export const REC_INTEGRATION_PARTNER_CATEGORY = "Send Recs To";
export const FETCH_ATTRIBUTES_URL = apiUrl("SetupZapper/GetAttributeList");
export const FETCH_LOCATION_HIERARCHY_ENTITIES_URL = apiUrl("SetupZapper/GetLocationTreeChildren");
export const FETCH_LOCATION_HIERARCHY_URL = apiUrl("SetupZapper/GetOrgLevelAutoCompleteList");
export const JOB_TITLE_RADIO_VALUE = 4;
export const LOCATION_RADIO_VALUE = 1;
export const OTHERS_CATEGORY = "Others";
export const REPORTS_CATEGORY = "Reports";
export const SAVE_ASSIGNMENT_URL = apiUrl("SetupZapper/SaveAssignments");
export const TEMPLATES_CATEGORY = "Templates";
export const VENDORS_CATEGORY = "Vendors";
export const USER_RADIO_VALUE = 2;
export const USER_ROLE_RADIO_VALUE = 3;
export const ANALYSIS_LAYERS_CATEGORY_INDEX = 2;
export const REPORTS_CATEGORY_INDEX = 3;
export const TEMPLATE_CATEGORY_INDEX = 4;
export const VENDOR_CATEGORY_INDEX = 5;
export const ANALYTICS_CATEGORY_INDEX = 6;
export const EQUATION_APPLICATION_CATEGORY_INDEX = 7;
export const EQUATION_PLANTING_CATEGORY_INDEX = 8;
export const REC_INTEGRATION_PARTNER_CATEGORY_INDEX = 9;
