import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { DialogBox, DialogBoxFooterType, Section } from "~/core";
import { onTextChange, preventBubbleUp } from "~/admin/utils";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { messages } from "../i18n-messages";
import { getDefaultRecords, model } from "./data";
import { keywords } from "~/admin/containers/keywords";
import ConnectionType from "./connection-type-list";
import { onsiteEditMode, JOHN_DEERE } from "./data/model";
// Style
import "./account.css";
import { ID } from "~/admin/data";
import { prepareSelectableOptions } from "~/admin/utils";
import { logFirebaseEvent } from "~/utils/firebase";
const REGISTERED_STATUSES = ["Registered", "New Registration.", "Registration Exists."];

export class Account_ extends Component {
    static propTypes = {
        autoExchange: PropTypes.bool,
        clearUrlForRegistration: PropTypes.func,
        closeSlidingPanel: PropTypes.func,
        companyGuid: PropTypes.string,
        connectionTypeList: PropTypes.array,
        fetchAccount: PropTypes.func,
        fetchUrlForRegistration: PropTypes.func,
        getOnSiteEquipmentList: PropTypes.func,
        intl: intlShape.isRequired,
        registrationUrl: PropTypes.string,
        needs: PropTypes.func,
        onsiteEquipmentList: PropTypes.array,
        onsiteUserKey: PropTypes.string,
        requestIds: PropTypes.object,
        saveAccount: PropTypes.func,
        setBreadcrumbs: PropTypes.func,
        userAccount: PropTypes.object,
        userRole: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.userAccount = getDefaultRecords();
        this.accountSaveRequestId = null;
        this.editData = null;
        this.fetchUrlRequestId = null;
        this.state = {
            [model.PROPS_CONNECTION_TYPES]: [],
            isEdit: false,
            showRegistrationModal: false,
            openedWindow: null,
        };
    }

    componentDidMount() {
        this.props.setBreadcrumbs();
        this._fetchInfo();
        logFirebaseEvent("account_telematics");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.userAccount !== this.props.userAccount) {
            this.userAccount = { ...nextProps.userAccount };
        }

        this._initializeDropdowns(nextProps.connectionTypeList);
        this._processFetchUrlRequest(nextProps);
        this._processSaveAccountRequest(nextProps);
    }

    _fetchInfo = () => {
        this.setState({ isEdit: false });
        this.accountFetchRequestId = this.props.needs([this.props.fetchAccount()]);
    };

    _initializeDropdowns = (options) => {
        if (this.props[model.PROPS_CONNECTION_TYPES] !== options) {
            this.setState({
                [model.PROPS_CONNECTION_TYPES]: prepareSelectableOptions(options, {
                    guid: model.PROPS_CONNECTION_GUID,
                    label: model.PROPS_CONNECTION_NAME,
                    id: ID,
                    details: model.PROPS_ONSITE_PARAM_LIST,
                }),
            });
        }
    };

    _onAddOrEditConnectionType = (isEdit) => {
        this.setState({ isEdit: isEdit });
        //get url and present modal
        this.fetchUrlRequestId = this.props.needs([
            this.props.fetchUrlForRegistration({ ...this.editData, isEdit }),
        ]);

        if (this._isJohnDeere()) {
            this._onSaveAccount(this.editData, isEdit ? onsiteEditMode.UPDATE : onsiteEditMode.ADD);
        }
    };

    _processFetchUrlRequest = (nextProps) => {
        if (this.fetchUrlRequestId !== null) {
            if (nextProps.requestIds[this.fetchUrlRequestId] === keywords.SUCCESS) {
                if (
                    this.props.registrationUrl != null &&
                    !REGISTERED_STATUSES.includes(this.props.registrationUrl)
                ) {
                    this.setState({
                        showRegistrationModal: true,
                    });
                } else if (REGISTERED_STATUSES.includes(this.props.registrationUrl)) {
                    this.props.clearUrlForRegistration();
                    this._onSaveAccount(
                        this.editData,
                        this.state.isEdit ? onsiteEditMode.UPDATE : onsiteEditMode.ADD
                    );
                }
                this.fetchUrlRequestId = null;
            } else if (nextProps.requestIds[this.fetchUrlRequestId] === keywords.FAILURE) {
                this.fetchUrlRequestId = null;
                this._fetchInfo();
            }
        }
    };

    _setEditData = (editData) => {
        if (this.editData !== editData) {
            this.editData = editData;
        }
    };

    _processSaveAccountRequest = (nextProps) => {
        if (this.accountSaveRequestId !== null) {
            if (
                nextProps.requestIds[this.accountSaveRequestId] === keywords.SUCCESS ||
                nextProps.requestIds[this.accountSaveRequestId] === keywords.FAILURE
            ) {
                this.accountSaveRequestId = null;
                this._fetchInfo();
            }
        }
    };

    _onSaveAccount = (record, editMode) => {
        const { userGuid, onsiteUserKey } = this.userAccount;
        const onsiteConnection = {
            userGuid,
            onsiteUserKey,
            onsiteConnection: record,
        };
        if (this.accountSaveRequestId === null) {
            this.accountSaveRequestId = this.props.needs([
                this.props.saveAccount({ onsiteConnection, editMode }),
            ]);
        }
    };

    _onCancel = () => {
        const { closeSlidingPanel } = this.props;
        closeSlidingPanel();
    };

    _onRecordDeleted = (record) => {
        this._onSaveAccount(record, onsiteEditMode.DELETE);
    };

    _onRegistrationCancel = () => {
        this.setState(
            {
                showRegistrationModal: false,
            },
            () => {
                this.props.clearUrlForRegistration();
                this._fetchInfo();
            }
        );
    };

    _onRegistrationClose = () => {
        this.setState(
            {
                showRegistrationModal: false,
            },
            () => {
                this.props.clearUrlForRegistration();
                this._onSaveAccount(
                    this.editData,
                    this.state.isEdit ? onsiteEditMode.UPDATE : onsiteEditMode.ADD
                );
            }
        );
    };

    _isJohnDeere = () => {
        return this.editData != null
            ? this.editData[model.PROPS_CONNECTION_NAME] === JOHN_DEERE
            : false;
    };

    _attemptRenderRegistrationModal = () => {
        return this.editData == null ||
            this.editData[model.PROPS_CONNECTION_NAME] == null ||
            this._isJohnDeere()
            ? null
            : this._basicRegistrationModal();
    };

    _basicRegistrationModal = () => {
        const { formatMessage } = this.props.intl;
        const { showRegistrationModal } = this.state;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.MULTI_ACTION}
                isOpen={showRegistrationModal}
                onClose={() => {
                    this.setState(
                        {
                            showRegistrationModal: false,
                        },
                        () => {
                            this.props.clearUrlForRegistration();
                            this._fetchInfo();
                        }
                    );
                }}
                title={formatMessage(messages.completeRegistration)}
                multiActionList={[
                    {
                        action: formatMessage(messages.registrationCompleted),
                        onAction: () => this._onRegistrationClose(),
                        isCancelStyle: false,
                    },
                    {
                        action: formatMessage(messages.registrationCancel),
                        onAction: () => this._onRegistrationCancel(),
                        isCancelStyle: true,
                    },
                ]}
            >
                <div>
                    <span className="click-me-link">
                        <a
                            href={this.props.registrationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {formatMessage(messages.clickHere)}
                        </a>
                    </span>
                    {formatMessage(messages.registrationLink, {
                        connectionTypeName: this.editData[model.PROPS_CONNECTION_NAME],
                    })}
                </div>
            </DialogBox>
        );
    };

    _renderAccount = () => {
        const { formatMessage } = this.props.intl;
        return (
            <div className="add-edit-panel">
                {!this.props.userRole.telematics ? null : (
                    <Section
                        className="grid-section"
                        headerText={formatMessage(messages.telematicsSetup)}
                    >
                        <ConnectionType
                            formatMessage={formatMessage}
                            itemListAlias={model.PROPS_ONSITE_CONNECTIONS_LIST}
                            needs={this.props.needs}
                            onAddOrEditConnectionType={(isEdit) => {
                                this._onAddOrEditConnectionType(isEdit);
                            }}
                            onTextChange={onTextChange}
                            onsiteEquipmentList={this.props.onsiteEquipmentList}
                            onsiteUserKey={this.props.onsiteUserKey}
                            options={this.state[model.PROPS_CONNECTION_TYPES]}
                            record={this.userAccount[model.PROPS_ONSITE_CONNECTIONS_LIST]}
                            setEditData={this._setEditData}
                            onRecordDeleted={this._onRecordDeleted}
                            userRole={this.props.userRole}
                            hideEdit={false}
                            hideDelete={false}
                            hideDateInput={false}
                            companyGuid={this.props.companyGuid}
                        />
                    </Section>
                )}
                {!this.props.registrationUrl ? null : this._attemptRenderRegistrationModal()}
            </div>
        );
    };

    render() {
        return (
            <div className="user-account-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel
                        navigateTo={{ parentNameCode: 100, childNameCode: 3 }}
                        component={() => this._renderAccount()}
                        close={() => this._onCancel()}
                    />
                </form>
            </div>
        );
    }
}

export const UserAccount = injectIntl(Account_);
